import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { ChangeEvent, useState } from "react";
import { UseMutationResult } from "react-query";
import { IndeterminateCheckbox, Input } from "../../components/Input/FormElements";
import { UserSelect } from "../../components/Input/Select/UserSelect";
import DictionaryList from "../../components/Table/DictionaryList";
import { Heading, HeadingColor, HeadingButton } from "../../components/Typography/Heading";
import { MachineGet, MachineUpdate } from "../../api/starterPackages/interfaces/machines";

export function EdgeMachineEdit({ edgeMachinePayload, mutationFunction, cancel }: { edgeMachinePayload: MachineGet, mutationFunction: UseMutationResult<MachineGet, AxiosError<unknown, any>, MachineUpdate, unknown>, cancel: () => void }) {
  const [edgeMachineFormData, setEdgeMachineFormData] = useState<MachineUpdate>(edgeMachinePayload);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      [name]: value
    })
  }

  const handleUserSelectChange = (name: string, newMail: string) => {
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      [name]: newMail
    })
  }

  const tableData = {
    "Machine Name": edgeMachinePayload.machine_name,
    "Machine Number": edgeMachinePayload.machine_number,
    "Edge Device Id": edgeMachinePayload.edge_device_id,
    "Is Edge Device Installed?": <IndeterminateCheckbox
      name="is_edge_installed"
      onChange={handleChange}
      checked={edgeMachineFormData.is_edge_installed}
    />,
    "Is Network configured?": <IndeterminateCheckbox
      name="is_network_configured"
      onChange={handleChange}
      checked={edgeMachineFormData.is_network_configured}
    />,
    "Is Opcua configured?": <IndeterminateCheckbox
      name="is_opcua_configured"
      onChange={handleChange}
      checked={edgeMachineFormData.is_opcua_configured}
    />,
    "Is pre-delivery inspection (PDI) completed?": <IndeterminateCheckbox
      name="is_pdi_done"
      onChange={handleChange}
      checked={edgeMachineFormData.is_pdi_done}
    />,
    "Order Manager": <UserSelect
      name="order_manager"
      userMail={edgeMachineFormData.order_manager}
      onMailChange={handleUserSelectChange}
    />,
    "Estimated Delivery Date": <Input
      type="date"
      name="estimated_delivery_date"
      value={edgeMachineFormData.estimated_delivery_date || ""}
      onChange={handleChange}
      className="p-1"
    />
  }

  return (
    <div>
      <Heading color={HeadingColor.Gray} processing={mutationFunction.isLoading}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={() => mutationFunction.mutate(edgeMachineFormData)}><PencilSquareIcon className="w-6 h-6 ml-5" />Save</HeadingButton>
        <HeadingButton onClick={() => cancel()}><XMarkIcon className="w-6 h-6 ml-5" />Cancel</HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} />
    </div>
  )
}