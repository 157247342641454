import { UseQueryOptions, useQuery } from "react-query";
import { graphApi } from "./graphApi";
import { AxiosError } from "axios";

export interface GraphApiResponse<T> {
  value: T
}

export interface UserResponse {
  id: string,
  displayName?: string,
  userPrincipalName?: string,
  mail?: string,
}

const useGraphBatch = (queryParams: string, queryOptions?: UseQueryOptions<GraphApiResponse<UserResponse[]>, AxiosError>) => useQuery<GraphApiResponse<UserResponse[]>, AxiosError>(
  ['graphApi', queryParams],
  () => graphApi.getUsersQuery(queryParams), {
    ...queryOptions
  }
);


export const graphApiQueries = {
  useGraphBatch
}