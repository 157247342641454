import { NavLink, Route, Routes } from "react-router-dom";
import OrderDetail from "./OrderDetail";
import OrderMachineList from "./OrderMachineList";

export default function OrderPage() {

  const tabs = [
    {
      title: "Order Info",
      href: "",
      element: <OrderDetail />
    },
    {
      title: "Machines",
      href: "contract-machines",
      element: <OrderMachineList />
    }
  ]

  return (
    <div className="space-y-2 h-full overflow-hidden overflow-y-auto flex flex-col">
      <div className="sticky top-0 flex space-x-1 rounded-sm bg-vibrant-blue p-2 z-20">
        {tabs.map((tab) => (
          <NavLink to={tab.href} end
            key={tab.href}
            className={({ isActive }: { isActive: boolean }) => `w-full rounded-sm p-2.5 font-medium ring-night-blue text-center ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1
                      ${isActive ? "bg-blue-50 text-vibrant-blue" : "text-blue-100 hover:bg-white/[0.25] hover:text-white"}`}
          >
            {tab.title}
          </NavLink>
        ))}
      </div>
      <div className=" relative z-10 bg-white rounded-sm p-2 space-y-2 relative grow">
        <Routes>
          {tabs.map((tab) => (
            <Route path={tab.href} key={tab.href} element={tab.element} />
          ))}
        </Routes>
      </div>
    </div>
  )
}