import { useState } from "react";
import { UserResponse, graphApiQueries } from "../../../api/graphApi/graphApiQueries";
import { ActionMeta, InputActionMeta, SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";

interface UserSelectProps {
  name: string,
  userMail: string,
  onMailChange: (name: string, mail: string) => void
}


export function UserSelect({ name, userMail, onMailChange }: UserSelectProps) {
  const [ selectedUser, setSelectedUser ] = useState<UserResponse | null>(null);
  const { isLoading: intialUserIsLoading } = graphApiQueries.useGraphBatch(`?$search="mail:${userMail}"`, {
      onSuccess: (data) => {
        if (data.value.length === 1)
          setSelectedUser(data.value[0])
      },
      enabled: !!userMail,
      refetchOnMount: "always"
    }
  );
  const [searchString, setSearchString] = useState<string>();
  const queryParams = searchString ? `?$search="mail:${searchString}"` : ""
  const { data: users, isLoading: userIsLoading } = graphApiQueries.useGraphBatch(queryParams);

  const formatOptionLabel = (data: UserResponse) => {
    return (
      <div className="md:grid md:grid-cols-2">
        <div className="truncate hidden md:block">{data.displayName}</div>
        <div>{data.mail}</div>
      </div>
    )
  }

  const onChange = (newValue: SingleValue<UserResponse>) => {
    setSelectedUser(newValue)
    onMailChange(name, newValue && newValue.mail ? newValue.mail : "")
  }

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    setSearchString(newValue)
  }

  const usersWithMail = users ? users.value.filter((user) => user.mail) : undefined

  return (
    <SearchableSelect
      name={name}
      inputValue={searchString}
      onInputChange={onInputChange}
      options={usersWithMail}
      value={selectedUser}
      isLoading={intialUserIsLoading || userIsLoading}
      onChange={onChange}
      getOptionValue={user => user.id}
      filterOption={null}
      isClearable={true}
      formatOptionLabel={formatOptionLabel} />
  )
}