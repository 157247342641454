import React, { useContext } from 'react';
import { createContext, useState } from 'react';






interface IVisibleTreeItemsContext {
  visibleTreeItems: Set<string>,
  addVisibleTreeItem: (item: string) => void,
  removeVisibleTreeItem: (item: string) => void,
}

const VisibleTreeItemsContext = createContext<IVisibleTreeItemsContext | undefined>(undefined);

function TreeContextProvider({ children }: {children: React.ReactNode}) {
  const [visibleTreeItems, setVisibleTreeItems] = useState<Set<string>>(new Set());
  const [selectedTreeItems, setSelectedTreeItems] = useState<Set<string>>(new Set());

  const addVisibleTreeItem = (treeItem: string) => {
    setVisibleTreeItems(prevItems => {
      prevItems.add(treeItem);
      return prevItems;
    })
  }

  const removeVisibleTreeItem = (treeItem: string) => {
    setVisibleTreeItems(prevItems => {
      prevItems.delete(treeItem);
      return prevItems;
    })
  }

  const addSelectedTreeItem = (treeItem: string) => {
    setSelectedTreeItems(prevItems => {
      prevItems.add(treeItem);
      return prevItems;
    })
  }

  const removeSelectedTreeItem = (treeItem: string) => {
    setSelectedTreeItems(prevItems => {
      prevItems.delete(treeItem);
      return prevItems;
    })
  }

  const treeContextValue = {
    visibleTreeItems,
    addVisibleTreeItem,
    removeVisibleTreeItem,
    selectedTreeItems,
    addSelectedTreeItem,
    removeSelectedTreeItem
  }

  return (
    <VisibleTreeItemsContext.Provider value={treeContextValue}>
      {children}
    </VisibleTreeItemsContext.Provider>
  )
}

const useVisibleItemsContext = (): IVisibleTreeItemsContext => {
  const context = useContext(VisibleTreeItemsContext);
  if (!context) {
    throw new Error('useVisibleItemsContext must be used within a TreeContextProvider');
  }
  return context;
};

export { TreeContextProvider, useVisibleItemsContext }