import { Dispatch, SetStateAction, useState } from 'react';
import Badge from '../../components/Typography/Badge';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../components/Input/FormElements';
import { Table, TBody, THead, TH, TD, TR } from '../../components/Table/TableComponents';
import { spApiQueries } from '../../api/starterPackages/starterPackagesQueries';
import { HeadingButton } from '../../components/Typography/Heading';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { BadgeColor } from '../../components/Typography/Badge';
import { MachineGet } from '../../api/starterPackages/interfaces/machines';
import AuthGuard from '../../components/Auth/AuthGuard';


export default function MachineList() {
  const [filter, setFilter] = useState('');
  const { isLoading, isError, isIdle, data, error } = spApiQueries.useGetEdgeMachines();

  if (isLoading || isIdle)
    return <div>Loading</div>

  if (isError)
    return <div className="w-full">Error: {error.message} {error.response?.data.detail}</div>

  const filteredData = data.filter((machine) =>
    machine.machine_number.toLowerCase().includes(filter.toLowerCase()) ||
    machine.machine_name?.toLowerCase().includes(filter.toLowerCase())
  );
  
  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <MachineTable machines={filteredData} filter={filter} setFilter={setFilter} />
      </div>
      <div className="block @2xl:hidden">
        <MachineCards machines={filteredData} filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}


// Used for both types of displaying
interface MachineListProps {
  machines: MachineGet[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}
function MachineTable({ machines, filter, setFilter }: MachineListProps) {
  const navigate = useNavigate();
  const { machineNumber } = useParams();

  const deviceItems = machines.map((machine) =>
    <TR onClick={() => navigate(`/machines/${machine.machine_number}`)} className={`${machineNumber === machine.machine_number ? "bg-gray" : "bg-white hover:bg-gray-100"}`} key={machine.machine_number}>
      <TD>{machine.machine_number}</TD>
      <TD>{machine.is_opcua_configured ? <Badge color={BadgeColor.Green}>done</Badge>: <Badge color={BadgeColor.Purple}>miss</Badge>}</TD>
      <TD>{machine.edge_device_id}</TD>
      <TD><Badge>{machine.contract?.contract_type}</Badge></TD>
      <TD><Badge>{machine.machine_name}</Badge></TD>
    </TR>
  );

  return (
    <div className="rounded-sm">
      <Table>
        <THead>
          <TR>
            <TH>Machine Number</TH>
            <TH>OPC-UA Config</TH>
            <TH>Edge Device</TH>
            <TH>Contract</TH>
            <TH>
              <div className="flex">
                <div className="grow">Machine Name</div>
                <AuthGuard requiredRoles={["user.admin", "service.editor"]}>
                  <HeadingButton onClick={() => navigate(`/machines/create`)} className="mr-5"><PlusCircleIcon className="w-6 h-6" />Add Machine</HeadingButton>
                </AuthGuard>
                <Input
                  type="text"
                  name="filter"
                  value={filter}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
                  placeholder="Search"
                  className="text-sm text-black -my-1 p-1"
                />
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{deviceItems}</TBody>
      </Table>
    </div>
  )
}

function MachineCards({ machines, filter, setFilter }: MachineListProps) {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const deviceItems = machines.map((machine) =>
    <div
      onClick={() => navigate(`/machines/${machine.machine_number}`)}
      key={machine.machine_number}
      className={`p-2 rounded-sm border border-1 ${deviceId === machine.machine_number ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge>{machine.machine_number}</Badge>
        <Badge>{machine.machine_name}</Badge>
      </div>
      <div className="font-medium truncate">{machine.machine_name}</div>
      <div className="truncate">{machine.machine_number}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium">
        <Input
          type="text"
          name="filter"
          value={filter}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
          placeholder="Search"
        />
      </div>
      {deviceItems}
    </div>
  )
}