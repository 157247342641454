import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Heading, HeadingButton, HeadingColor } from "../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { spApiQueries } from "../../api/starterPackages/starterPackagesQueries";
import DictionaryList, { DictionaryListEntries } from "../../components/Table/DictionaryList";
import { useState } from "react";
import { IndeterminateCheckbox } from "../../components/Input/FormElements";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { EdgeMachineEdit } from "./EdgeMachineEdit";
import AuthGuard from "../../components/Auth/AuthGuard";
import { formatError } from "../../utils/formatError";

var tableData: DictionaryListEntries = {
  "Machine Name": "",
  "Machine Number": "",
  "Edge Device Id": "",
  "Is Edge Device Installed?": "",
  "Is Network configured?": "",
  "Is Opcua configured?": "",
  "Is pre-delivery inspection (PDI) completed?": "",
  "Order Manager": "",
  "Estimated Delivery Date": ""
}

export default function MachineDetail() {
  const { machineNumber } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false); // Same component as mutation, same component as save, cancel and edit button
  const { error, isFetching, data, status } = spApiQueries.useGetEdgeMachine(machineNumber);
  const queryClient = useQueryClient();
  const updateEdgeMachineMutation = spApiQueries.useUpdateEdgeMachine(machineNumber, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachine', machineNumber] })
      setIsEditing(false);
    },
    onError(error) {
      toast.error(`ERROR: Could update edge machine information. ${formatError(error)}`)
    },
  });
  const deleteEdgeMachineMutation = spApiQueries.useDeleteEdgeMachine();
  
  const handleDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this machine?');
    if (confirmed) {
      deleteEdgeMachineMutation.mutate(machineNumber, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachines'] })
          navigate('/machines')
        },
        onError(error) {
          toast.error(`ERROR: Could not delete edge machine ${machineNumber}. ${error.message}`)
        }
      })
    }
  };

  if (data) {
    tableData = {
      "Machine Name": data.machine_name,
      "Machine Number": data.machine_number,
      "Edge Device Id": <NavLink to={`/devices/${data.edge_device_id}/machines`}>{data.edge_device_id}</NavLink>,
      "Is Edge Device Installed?": <IndeterminateCheckbox checked={data.is_edge_installed} disabled />,
      "Is Network configured?": <IndeterminateCheckbox checked={data.is_network_configured} disabled />,
      "Is Opcua configured?": <IndeterminateCheckbox checked={data.is_opcua_configured} disabled />,
      "Is pre-delivery inspection (PDI) completed?": <IndeterminateCheckbox checked={data.is_pdi_done} disabled />,
      "Order Manager": data.order_manager,
      "Estimated Delivery Date": data.estimated_delivery_date
    }

    if (isEditing) {
      return (
        <EdgeMachineEdit edgeMachinePayload={data} mutationFunction={updateEdgeMachineMutation} cancel={() => setIsEditing(false)} />
      )
    }
  }

  return (
    <div>
      <Heading processing={isFetching} color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <AuthGuard requiredRoles={["user.admin", "service.editor"]}>
          <HeadingButton onClick={() => setIsEditing(!isEditing)} disabled={status !== "success"}><PencilSquareIcon className="w-6 h-6 ml-5" />Edit</HeadingButton>
        </AuthGuard>
        <AuthGuard requiredRoles={["user.admin"]}>
          <HeadingButton onClick={handleDelete} disabled={status !== "success"}><XMarkIcon className="w-6 h-6 ml-5" />Delete</HeadingButton>
        </AuthGuard>
      </Heading>
      <DictionaryList dictionary={tableData} processing={status === "loading" || status === "idle"} error={error?.message} />
    </div>
  )
}
