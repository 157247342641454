import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { useState } from "react";
import { UseMutationResult } from "react-query";
import { Input, IndeterminateCheckbox } from "../../components/Input/FormElements";
import DictionaryList from "../../components/Table/DictionaryList";
import { Heading, HeadingColor, HeadingButton } from "../../components/Typography/Heading";
import { UserSelect } from "../../components/Input/Select/UserSelect";
import { ContractGet, ContractType, ContractUpdate } from "../../api/starterPackages/interfaces/contracts";
import { ContractTypeSelect } from "../../components/Input/Select/ContractTypeSelect";
import { CurrencySelect } from "../../components/Input/Select/CurrencySelect";

export default function ContractEdit({ contract, mutationFunction, cancel }: { contract: ContractGet, mutationFunction: UseMutationResult<ContractGet, AxiosError<unknown, any>, ContractUpdate, unknown>, cancel: () => void }) {
  const [contractFormData, setContractFormData] = useState<ContractGet>(contract);

  const handleChange = (ev: any) => {
    const target = ev.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setContractFormData({
      ...contractFormData,
      [name]: value
    })
  }

  const handleContractTypeChange = (contract_type: ContractType) => {
    setContractFormData(formData => ({
      ...formData,
      contract_type: contract_type
    }))
  }

  const handleCurrencyChange = (currency: string) => {
    setContractFormData(formData => ({
      ...formData,
      initial_contract_amount_currency: currency
    }))
  }

  const handleUserSelectChange = (name: string, value: string) => {
    setContractFormData(formData => ({
      ...formData,
      [name]: value
    }))
  }


  const tableData = {
    "Order": `${contractFormData.order_id} - ${contractFormData.pos_id}`,
    "Contract Id": <Input
      type="text"
      name="contract_id"
      value={contractFormData.contract_id}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />,
    "Start Date": <Input
      type="date"
      name="start_date"
      value={contractFormData.start_date}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />,
    "End Date": <Input
      type="date"
      name="end_date"
      value={contractFormData.end_date}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />,
    "Estimated Edge Online Date": <Input
      type="date"
      name="estimated_edge_online_date"
      value={contractFormData.estimated_edge_online_date}
      onChange={handleChange}
      className="-my-1 p-1"
    />,
    "Initial contract amount": <Input
      type="text"
      name="initial_contract_amount"
      value={contractFormData.initial_contract_amount}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />,
    "Initial contract currency": <CurrencySelect
        currency={contractFormData.initial_contract_amount_currency}
        onCurrencyChange={handleCurrencyChange}
      />,
    "Contract Type": <ContractTypeSelect
      contractType={contractFormData.contract_type}
      onContractTypeChange={handleContractTypeChange} />,
    "Contact Gea Service": <UserSelect
      name="contract_gea_service"
      userMail={contractFormData.contract_gea_service}
      onMailChange={handleUserSelectChange} />,
    "Contact Gea Sales": <UserSelect
      name="contract_gea_sales"
      userMail={contractFormData.contract_gea_sales}
      onMailChange={handleUserSelectChange} />,
    "Contact Customer (Email)": <Input
      type="text"
      name="contract_customer"
      value={contractFormData.contract_customer}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />,
    "Contact Admin Insight Partner": <UserSelect
      name="contract_customer_admin_insight_partner"
      userMail={contractFormData.contract_customer_admin_insight_partner || ""}
      onMailChange={handleUserSelectChange} />,
    "Are network settings available": <IndeterminateCheckbox
      name="is_network_setting_available"
      checked={contractFormData.is_network_setting_available}
      onChange={handleChange}
    />,
    "Bonus recipient": <UserSelect
      name="bonus_recipient"
      userMail={contractFormData.bonus_recipient}
      onMailChange={handleUserSelectChange} />,
    "Comment": <Input
      name="comment"
      value={contractFormData.comment}
      onChange={handleChange}
      className="-my-1 p-1 pr-10"
    />
  }

  return (
    <div>
      <Heading color={HeadingColor.Gray} processing={mutationFunction.isLoading}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={() => mutationFunction.mutate(contractFormData)}><PencilSquareIcon className="w-6 h-6 ml-5" />Save</HeadingButton>
        <HeadingButton onClick={() => cancel()}><XMarkIcon className="w-6 h-6 ml-5" />Cancel</HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} />
    </div>
  )
}
