
import { NavLink } from 'react-router-dom';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import SignOutButton from '../Auth/SignOutButton';

import logo from '../../assets/GEA_Logo_wo_Claim_sRGB_VibrantBlue.png';
import { Fragment, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import AuthGuard from '../Auth/AuthGuard';

const navigation = [
  { name: 'Dashboard', href: '/', current: true, requiredRoles: ["user.admin", "service.viewer", "service.editor", "contract.viewer", "contract.editor"] },
  { name: 'Devices', href: '/devices', current: true, requiredRoles: ["user.admin", "service.viewer", "service.editor"] },
  { name: 'Machines', href: '/machines', current: true, requiredRoles: ["user.admin", "service.viewer", "service.editor"] },
  { name: 'Contracts', href: '/contracts', current: true, requiredRoles: ["user.admin", "contract.viewer", "contract.editor"] },
  { name: 'Orders', href: '/orders', current: true, requiredRoles: ["user.admin", "contract.viewer", "contract.editor", "service.editor"] },
]

export default function NavigationBar() {

  const { instance } = useMsal();
  const [initials, setInitials] = useState<string | undefined>('');

  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    if (activeAccount) {
      setInitials(activeAccount.name?.split(' ').map(n => n[0]).join(''));
    } else {
      setInitials('');
    }
  }, [activeAccount]);

  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-[1001]">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-screen-3xl px-4">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-sm p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="GEA"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <AuthGuard requiredRoles={item.requiredRoles} key={item.name}
                        children={<NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }: { isActive: boolean }) => `px-3 py-2 text-sm font-medium ${isActive ? "underline" : "hover:text-vibrant-blue"}`}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </NavLink>}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full text-sm border border-gray-500 hover:border-gray-900 focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <div className="h-8 w-8 rounded-full items-center py-1.5">{initials}</div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            <SignOutButton />
                          </>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

            </div>
          </div>

          <Disclosure.Panel className="sm:hidden relative">
            <div className="absolute space-y-2 px-2 pb-3 pt-2 bg-white w-full">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.href}
                  className={`${item.current ? 'bg-vibrant-blue text-white' : 'text-gray-300'} hover:bg-night-blue hover:text-white block rounded-sm px-3 py-2 text-base font-medium`}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}