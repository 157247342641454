import { SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";
import { MachineName, machineNames } from '../../../api/starterPackages/interfaces/machines';

interface MachineNameSelectProps {
  name: string,
  machineName: MachineName,
  onMachineNameChange: (name: string, contractType: MachineName) => void
}

export function MachineNameSelect({ name, machineName, onMachineNameChange }: MachineNameSelectProps) {

  const onHandleChange = (newValue: SingleValue<any>) => {
    onMachineNameChange(name, newValue ? newValue.value : "")
  }

  const value = {
    value: machineName,
    label: machineName,
  }

  const machineNameOptions = machineNames.map((machineName) => {
    return {
      label: machineName,
      value: machineName
    }
  });

  return (
    <SearchableSelect
      options={machineNameOptions}
      value={value}
      onChange={onHandleChange}
      isClearable={false}
    />
  )
}
