import * as Sentry from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const tracePropagationTarget = process.env.REACT_APP_API_URI || '';

// Config object to be passed to queryClient on initialization
export const sentryConfig = {
  dsn: "https://15e1d2794164ab3a0267cf65d2f76523@o4506270497832960.ingest.sentry.io/4506337292451840",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [ tracePropagationTarget ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}