import { useParams } from "react-router-dom";
import Button from "../../../components/Input/Button";
import { useMutation } from 'react-query';
import { toast } from "react-toastify";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";


export function DeviceSemsCheck () {
  const { deviceId } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      let resp = await easApi.getDeviceCmdStatus(deviceId)

      if (resp.moduleStatus === "Disconnected"){
        toast.warning("this function require a connected <gea-app-cmd-proxy> module -> check Module-Config")
        resp.showSuccess = false
      }
      else{
        resp = await easApi.postDeviceCmdSmartemsCheck(deviceId)
        resp.showSuccess = true
      }
      return resp
    },

    onError: (error: any) => {
      toast.error(`ERROR: Could not apply config update: ${error.code} ${JSON.stringify(error.response?.data)}`)
    },

    onSuccess: (resp: any) => {
      if (resp.showSuccess){
        toast.success("activate config update on device -> check command history")
      }
    }
  });

  return <div>
    <Button processing={isLoading} onClick={() => mutate()}>Activate Config</Button>
    </div>
}