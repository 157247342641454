import axios from "axios";
import { msalInstance } from "../..";
import { graphApiLoginRequest } from "../../config/authConfig";

export const graphApiInstance = axios.create({
  baseURL: `https://graph.microsoft.com/v1.0`,
});

graphApiInstance.interceptors.request.use(async function (config: any) {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(graphApiLoginRequest);
    config.headers["Authorization"] = `Bearer ${tokenResponse.accessToken}`;
    config.headers["ConsistencyLevel"] = `eventual`;
  } catch (error) {
    msalInstance.acquireTokenRedirect(graphApiLoginRequest);
  }
  return config;
}, undefined);

const getUsersQuery = async (searchQuery: string) => {
    const { data } = await graphApiInstance.get(`https://graph.microsoft.com/v1.0/users${searchQuery}`);
    return data;
  }

export const graphApi = {
  getUsersQuery
}