import { useState } from 'react';

interface InputDialogProps {
    basePath: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (inputValue: string) => void;
  }
  
  
  export default function InputDialog ({ basePath, isOpen, onClose, onConfirm }: InputDialogProps){
    const [inputValue, setInputValue] = useState('');

    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
          <h2 className="text-lg text-center font-semibold">Create new Directory</h2>
          <label htmlFor="dirName" className="block text-sm font-medium text-gray-700 mt-5">
            {basePath}/
          </label>
          <input
            id="dirName"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
            placeholder='Directory Name'
          />
          <div className="mt-6 flex justify-center space-x-4">
            <button
              onClick={() => onConfirm(`${basePath}/${inputValue}`)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              OK
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };