import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { spApiQueries } from '../../api/starterPackages/starterPackagesQueries';
import { TBody, TD, TH, THead, TR, Table } from '../../components/Table/TableComponents';
import Badge from '../../components/Typography/Badge';
import { BadgeColor } from '../../components/Typography/Badge';
import { MachineForeign, MachineGet } from '../../api/starterPackages/interfaces/machines';
import Button, { ButtonColor, ButtonSize } from '../../components/Input/Button';
import { ObjectSelect } from '../../components/Input/Select/ObjectSelect';
import AuthGuard from '../../components/Auth/AuthGuard';


export default function ContractMachineList() {
  const [filter, setFilter] = useState('');
  const { orderId, posId } = useParams();
  const { isLoading, isError, isIdle, data, error } = spApiQueries.useGetOrder(orderId, posId);

  if (isLoading || isIdle)
    return <div>Loading</div>

  if (isError)
    return <div>Error: {error.message}</div>

  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <MachineTable machines={data.machines} filter={filter} setFilter={setFilter} />
      </div>
      <div className="block @2xl:hidden">
        <MachineCards machines={data.machines} filter={filter} setFilter={setFilter} />
      </div>
      <AddMachine />
    </div>
  );
}


// Used for both types of displaying
interface MachineListProps {
  machines: MachineForeign[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}
function MachineTable({ machines, filter, setFilter }: MachineListProps) {
  const navigate = useNavigate();
  const { machineNumber, orderId, posId } = useParams();

  const { mutate } = spApiQueries.useRemovedMachinefromOrder(orderId, posId);

  const handleDelete = (machineNumber: string) => {
    const confirmed = window.confirm('Are you sure you want to remove this machine from the contract?');
    if (confirmed) {
      mutate(machineNumber)
    }
  };

  const deviceItems = machines.map((machine) =>
    <TR className={`${machineNumber === machine.machine_number ? "bg-gray" : "bg-white"}`} key={machine.machine_number}>
      <TD><Button onClick={() => navigate(`/machines/${machine.machine_number}`)} size={ButtonSize.Small}>{machine.machine_number}</Button></TD>
      <TD>{machine.is_opcua_configured ? <Badge color={BadgeColor.Green}>done</Badge> : <Badge color={BadgeColor.Purple}>miss</Badge>}</TD>
      <TD><Badge>{machine.machine_name}</Badge></TD>
      <AuthGuard requiredRoles={["user.admin", "contract.editor"]}>
        <TD><Button className="flex items-center" color={ButtonColor.Red} size={ButtonSize.Small} onClick={() => handleDelete(machine.machine_number)}><XCircleIcon className="h-6 w-6 text-black mr-1" />Remove</Button></TD>
      </AuthGuard>
    </TR>
  );

  return (
    <>
      <div className="rounded-sm">
        <Table>
          <THead>
            <TR>
              <TH>Machine Number</TH>
              <TH>OPC-UA Config</TH>
              <TH>Machine Name</TH>
              <AuthGuard requiredRoles={["user.admin", "contract.editor"]}>
                <TH>Remove</TH>
              </AuthGuard>
            </TR>
          </THead>
          <TBody>{deviceItems}</TBody>
        </Table>
      </div>
    </>
  )
}

function MachineCards({ machines, filter, setFilter }: MachineListProps) {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const deviceItems = machines.map((machine) =>
    <div
      onClick={() => navigate(`/machines/${machine.machine_number}`)}
      key={machine.machine_number}
      className={`p-2 rounded-sm border border-1 ${deviceId === machine.machine_number ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge>{machine.machine_number}</Badge>
        <Badge>{machine.machine_name}</Badge>
      </div>
      <div className="font-medium truncate">{machine.machine_name}</div>
      <div className="truncate">{machine.machine_number}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium text-white">
        Machines
      </div>
      {deviceItems}
    </div>
  )
}

function AddMachine() {
  const [isAddingMachine, setIsAddingMachine] = useState(false);
  const [machine, setMachine] = useState<MachineGet>();
  const { orderId, posId } = useParams();

  const addMachineToOrder = spApiQueries.useAddMachineToOrder(orderId, posId);
  const { data: edgeMachines } = spApiQueries.useGetEdgeMachines(`order eq null`);

  const handleMachineNumberChange = (machine: MachineGet) => {
    setMachine(machine)
  }

  const onAdd = () => {
    if (machine)
      addMachineToOrder.mutate(machine.machine_number, {
        onSuccess: () => {
          setIsAddingMachine(false)
          setMachine(undefined)
        }
      })
  }

  return (
    <>
      {isAddingMachine ?
        <div className="flex space-x-2 mt-2">
          <div className="grow">
            <ObjectSelect<MachineGet>
              value={machine}
              options={edgeMachines}
              id={"machine_number"}
              columns={["machine_name"]}
              onSelectChange={handleMachineNumberChange} />
          </div>
          <Button size={ButtonSize.Small} onClick={onAdd} disabled={!machine}>Save</Button>
          <Button onClick={() => setIsAddingMachine(false)} size={ButtonSize.Small}>Cancel</Button>
        </div>
        :
        <AuthGuard requiredRoles={["user.admin", "contract.editor"]}>
          <Button className="flex justify-center items-center w-full mt-2" onClick={() => setIsAddingMachine(true)}>
            <PlusCircleIcon className="w-6 h-6" />Add Machine to order
          </Button>
        </AuthGuard>
      }
    </>
  )
}