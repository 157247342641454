import { ChangeEvent, useState } from "react";
import { spApiQueries } from "../../api/starterPackages/starterPackagesQueries";
import { IndeterminateCheckbox, Input } from "../../components/Input/FormElements";
import { Heading, HeadingButton, HeadingColor } from "../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DictionaryList from "../../components/Table/DictionaryList";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserSelect } from "../../components/Input/Select/UserSelect";
import { MachineCreate, MachineName } from "../../api/starterPackages/interfaces/machines";
import { MachineNameSelect } from "../../components/Input/Select/MachineNameSelect";
import { EdgeDeviceGet } from "../../api/starterPackages/interfaces/edge_devices";
import { ObjectSelect } from "../../components/Input/Select/ObjectSelect";
import { formatError } from "../../utils/formatError";

export default function EdgeMachineCreate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const edgeDeviceIdFromQuery = searchParams.get('edgeDeviceId') || "";
  const [edgeMachineFormData, setEdgeMachineFormData] = useState<MachineCreate>({
    "is_edge_installed": false,
    "is_network_configured": false,
    "is_opcua_configured": false,
    "is_pdi_done": false,
    "order_manager": "",
    "estimated_delivery_date": "",
    "machine_number": "",
    "edge_device_id": edgeDeviceIdFromQuery,
    "machine_name": "CutMaster"
  });
  const { data: selectedEdgeDevice } = spApiQueries.useGetEdgeDevice(edgeMachineFormData.edge_device_id);
  const { data: edgeDeviceOptions } = spApiQueries.useGetEdgeDevices();
  const queryClient = useQueryClient();
  const mutationFunction = spApiQueries.useCreateEdgeMachine();

  const onSubmit = () => {
    mutationFunction.mutate(edgeMachineFormData, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachines'] })
        navigate(`/machines/${edgeMachineFormData.machine_number}`)
      },
      onError(error) {
        toast.error(`ERROR: Could not create edge machine. ${formatError(error)}`)
      },
    })
  }

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      [name]: value
    })
  }

  const handleEdgeDeviceChange = (edgeDevice: EdgeDeviceGet) => {
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      edge_device_id: edgeDevice.edge_device_id
    })
  }

  const handleUserSelectChange = (name: string, userMail: string) => {
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      [name]: userMail
    })
  }

  const handleEgdeMachineNameChange = (name: string, machineName: MachineName) => {
    setEdgeMachineFormData({
      ...edgeMachineFormData,
      [name]: machineName
    })
  }

  if (edgeDeviceOptions)
    edgeDeviceOptions.sort((a, b) => ((a.edge_device_id > b.edge_device_id) ? 1 : -1))

  const tableData = {
    "Machine Name": <MachineNameSelect
      name="machine_name"
      machineName={edgeMachineFormData.machine_name}
      onMachineNameChange={handleEgdeMachineNameChange}
    />,
    "Machine Number": <Input
      type="text"
      name="machine_number"
      value={edgeMachineFormData.machine_number}
      onChange={handleInputChange}
      className="p-1 pr-10"
    />,
    "Edge Device Id": <ObjectSelect<EdgeDeviceGet>
      value={selectedEdgeDevice}
      options={edgeDeviceOptions}
      id={"edge_device_id"}
      columns={[]}
      onSelectChange={handleEdgeDeviceChange} />,
    "Is Edge Device Installed?": <IndeterminateCheckbox
      name="is_edge_installed"
      onChange={handleInputChange}
      checked={edgeMachineFormData.is_edge_installed}
    />,
    "Is Network configured?": <IndeterminateCheckbox
      name="is_network_configured"
      onChange={handleInputChange}
      checked={edgeMachineFormData.is_network_configured}
    />,
    "Is Opcua configured?": <IndeterminateCheckbox
      name="is_opcua_configured"
      onChange={handleInputChange}
      checked={edgeMachineFormData.is_opcua_configured}
    />,
    "Is pre-delivery inspection (PDI) completed?": <IndeterminateCheckbox
      name="is_pdi_done"
      onChange={handleInputChange}
      checked={edgeMachineFormData.is_pdi_done}
    />,
    "Order Manager": <UserSelect
      name="order_manager"
      userMail={edgeMachineFormData.order_manager}
      onMailChange={handleUserSelectChange}
    />,
    "Estimated Delivery Date": <Input
      type="date"
      name="estimated_delivery_date"
      value={edgeMachineFormData.estimated_delivery_date}
      onChange={handleInputChange}
      className="p-1"
    />
  }

  return (
    <div>
      <Heading color={HeadingColor.Gray} processing={mutationFunction.isLoading}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={onSubmit}><PencilSquareIcon className="w-6 h-6 ml-5" />Save</HeadingButton>
        <HeadingButton onClick={() => navigate('/machines')}><XMarkIcon className="w-6 h-6 ml-5" />Cancel</HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} />
    </div>
  )
}
