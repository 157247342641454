import { TableCellsIcon } from "@heroicons/react/24/outline";
import { Heading, HeadingColor } from "../../../components/Typography/Heading";
import { useParams } from "react-router-dom";

export default function GrafanaFrames() {
  const { deviceId } = useParams();

  return (
    <div className="space-y-2">
      <Heading color={HeadingColor.Gray}><TableCellsIcon className="w-7 h-7 mr-1" />Data</Heading>
      <div className="grid grid-cols-1">
        <iframe src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-3d&to=now&panelId=7&refresh=10s`} title="graph1" width="100%" height="60" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <iframe src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-6h&to=now&panelId=4&refresh=10s`} title="graph2" width="100%" height="200" />
        <iframe src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-6h&to=now&panelId=5&refresh=10s`} title="graph3" width="100%" height="200" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <iframe src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-6h&to=now&panelId=2&refresh=10s`} title="graph4" width="100%" height="200" />
        <iframe src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-2h&to=now&panelId=9&refresh=10s`} title="graph5" width="100%" height="120" />
      </div>
    </div>
  )
}