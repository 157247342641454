import { ContractForeign } from "./contracts";
import { EdgeDeviceForeign } from "./edge_devices";
import { OrderForeign } from "./orders";

export const machineNames = [
    "CutMaster",
    "TiroLabel",
    "PowerPak",
    "PowerSort",
    "OptiRobot",
    "UniSlicer",
    "MegaSlicer",
    "MegaSlicer-CAM",
    "GigaSlicer",
    "DualSlicer",
    "OptiSlicer",
    "ShingleLoader",
    "FlexLoader",
    "RobotLoader",
    "TwinStar",
    "LineControl"
] as const;


export type MachineName = (typeof machineNames)[number]

export interface MachineUpdate {
    is_edge_installed: boolean;
    is_network_configured: boolean;
    is_opcua_configured: boolean;
    is_pdi_done: boolean;
    order_manager: string; // Ensure FIELD_PATTERN_EMAIL is validated at runtime
    estimated_delivery_date: string; // Assuming ISO 8601 date format
}

export interface MachineCreate extends MachineUpdate {
    machine_number: string;
    machine_name: MachineName;
    edge_device_id: string; // Ensure FIELD_PATTERN_EDGE_DEVICE is validated at runtime
}

export interface MachineGet extends MachineCreate {
    order?: OrderForeign | null;
    contract?: ContractForeign | null;
    edge_device?: EdgeDeviceForeign | null;
}

export interface MachineForeign extends MachineCreate {}