import { MachineForeign } from "./machines";
import { OrderForeign } from "./orders";

export const contractTypes = [
    "StarterPackage",
    "StarterPackage Plus",
    "Maintenance",
    "Full Service"
] as const;

export type ContractType = (typeof contractTypes)[number]

export interface ContractUpdate {
    contract_id: string;
    start_date: string; // Assuming ISO 8601 date format
    end_date: string; // Assuming ISO 8601 date format
    estimated_edge_online_date: string; // Assuming ISO 8601 date format
    initial_contract_amount: number;
    initial_contract_amount_currency: string;
    contract_type: ContractType;
    contract_gea_service: string;
    contract_gea_sales: string;
    contract_customer: string;
    contract_customer_admin_insight_partner?: string;
    is_network_setting_available: boolean;
    bonus_recipient: string;
    comment: string;
}

export interface ContractCreate extends ContractUpdate {
    pos_id: number;
    order_id: number;
}

export interface ContractForeign extends ContractCreate {
    // Additional properties or methods if needed
}

export interface ContractGet extends ContractCreate {
    order: OrderForeign;
    machines: MachineForeign[];
}