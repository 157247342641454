import { useState } from "react";
import ConnectionParamsForm from './ConnectionParamsForm';
import NodeIdTree from "./NodeIdTree";
import Button from "../../components/Input/Button";
import { TreeContextProvider } from "./TreeContext";

export interface ConnectionParams {
  endpoint: string,
  credentials: UserNameCredentials | AnonymousCredentials,
  messageSecurityMode: string,
  securityPolicy: string
}

export interface UserNameCredentials {
  type: "UserName",
  userName: string,
  password: string
}

export interface AnonymousCredentials {
  type: "Anonymous",
}

export default function OPCUABrowser({ endpoint }: { endpoint: string }) {
  const [connectionParams, setConnectionParams] = useState<ConnectionParams>();

  return (
    <div className="bg-white space-y-2">
      {connectionParams ?
        <>
          <Button onClick={() => setConnectionParams(undefined)}>Change connection Parameters</Button>
          <TreeContextProvider>
            <NodeIdTree connectionParams={connectionParams} />
          </TreeContextProvider>
        </> :
        <ConnectionParamsForm endpoint={endpoint} setConnectionParams={setConnectionParams} />
      }
    </div>
  )
}