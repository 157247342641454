import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Subscription, easApiHooks } from '../../api/edgeAdministrationShell/easApiHooks';
import { toast } from 'react-toastify';
import { NodeIdTreeLayer } from './NodeIdTreeLayer';
import { NodeIdReference } from './NodeIdTreeItem';
import { NodeIdValueContextProvider } from './NodeIdValueContext';

interface ConnectionParams {
  endpoint: string,
  credentials: {
    type: "UserName",
    userName: string,
    password: string
  } | {
    type: "Anonymous"
  },
  messageSecurityMode: string,
  securityPolicy: string
}

const rootNodeId = {
  referenceTypeId: "",
  isForward: true,
  nodeId: "i=84",
  browseName: {
    namespaceIndex: 0,
    name: "Root"
  },
  displayName: {
    text: "Root"
  },
  nodeClass: "Object",
}

interface SessionDiagnosticsArray {
  value: {
    clientDescription: {
      applicationName: {
        text: string
      }
    }
  }[]
}

export default function NodeIdTree({ connectionParams }: { connectionParams: ConnectionParams }) {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const { deviceId } = useParams();

  /**
   * Retrieves the moduleName of the opcua-client module from the OPCUA server.
   * nodeId: ns=0;i=3707 contains an array with sessions;
   * from the sessions we can retrieve the module name of the opcuaClient.
   */
  const { data: opcuaClientModuleName } = easApiHooks.useReadNodeId<string | undefined>(deviceId, connectionParams, 'ns=0;i=3707', {
    refetchOnMount: 'always',
    select: (data) => {
      if (data.payload.value) {
        const sessionDiagnosticsArray = (data.payload.value as SessionDiagnosticsArray).value.filter(
          (session) => session.clientDescription.applicationName.text.startsWith('gea-app-opcua-client'));
        if (sessionDiagnosticsArray.length === 1) {
          return sessionDiagnosticsArray[0].clientDescription.applicationName.text;
        }
      }
      return undefined;
    }
  });

  // Retrieve twin config and set subscriptions array
  easApiHooks.useGetTwinConfig(deviceId, opcuaClientModuleName, {
    enabled: !!opcuaClientModuleName,
    refetchOnMount: 'always',
    onSuccess: (data) => setSubscriptions(data.subscriptions),
    onError: (error) => {
      toast.error(`ERROR: Could not retrieve current opcua configuration: ${error.code} ${JSON.stringify(error.response?.data)}`)
    },
  });

  const toggleNodeIdFromSubscription = (nodeId: string, subscriptionName: string) => {
    let index = subscriptions.findIndex(subscription => subscription.subscriptionName === subscriptionName);
    if (index === -1)
      return;

    const nodeIds = subscriptions[index].nodeIds;
    if (Array.isArray(nodeIds)) {
      if (nodeIds.includes(nodeId)) {  // Remove if subscription already contains node id
        subscriptions[index].nodeIds = nodeIds.filter(node => node !== nodeId);
      } else {                                              // Add if subscription does not contain nodeid
        nodeIds.push(nodeId);
      }
    } else {
      if (nodeId in nodeIds) {
        delete nodeIds[nodeId]
      } else {
        nodeIds[nodeId] = {
          alias: null,
          filter: null
        }
      }
    }

    const updatedSubscriptions = [...subscriptions];
    updatedSubscriptions[index] = subscriptions[index];

    setSubscriptions(updatedSubscriptions);
  }

  const useBrowseNodeIds = (nodeIdReferences: NodeIdReference[]) => {
    const nodeIds = nodeIdReferences.map(nodeIdReference => nodeIdReference.nodeId);
    return easApiHooks.useBrowseNodeIds(deviceId, connectionParams, nodeIds);
  }

  const nodeIds = [rootNodeId];

  return (
    <>
      <table className="w-full table-fixed">
        <thead className="bg-vibrant-blue text-white font-bold">
          <tr>
            <td className="p-2 w-[30%] truncate">Node Id</td>
            {subscriptions.map((subscription, index) =>
              <td className="truncate" key={index}>{subscription.subscriptionName}</td>
            )}
            <td className="w-[10%] truncate">Value</td>
          </tr>
        </thead>
        <NodeIdValueContextProvider connectionParams={connectionParams}>
          <tbody className="divide-y">
            <NodeIdTreeLayer
              useBrowseNodeIds={useBrowseNodeIds}
              nodeIdReferences={nodeIds}
              depth={0}
              subscriptions={subscriptions}
              toggleNodeIdFromSubscription={toggleNodeIdFromSubscription} />
          </tbody>
        </NodeIdValueContextProvider>
      </table>
    </>
  )
}