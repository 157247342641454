import { useState } from "react";
import { spApiQueries } from "../../api/starterPackages/starterPackagesQueries";
import { IndeterminateCheckbox, Input } from "../../components/Input/FormElements";
import { Heading, HeadingButton, HeadingColor } from "../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DictionaryList, { DictionaryListEntries } from "../../components/Table/DictionaryList";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CurrencySelect } from "../../components/Input/Select/CurrencySelect";
import { ContractTypeSelect } from "../../components/Input/Select/ContractTypeSelect";
import { UserSelect } from "../../components/Input/Select/UserSelect";
import { OrderGet } from "../../api/starterPackages/interfaces/orders";
import { ContractCreate, ContractType } from "../../api/starterPackages/interfaces/contracts";
import { formatError } from "../../utils/formatError";
import { ObjectSelect } from "../../components/Input/Select/ObjectSelect";

export default function ContractCreateForm() {
  const navigate = useNavigate();
  const [contractFormData, setContractFormData] = useState<ContractCreate>({
    "contract_id": "",
    "estimated_edge_online_date": "",
    "order_id": 0,
    "pos_id": 0,
    "contract_customer_admin_insight_partner": "",
    "initial_contract_amount": 0,
    "is_network_setting_available": false,
    "initial_contract_amount_currency": "EUR",
    "bonus_recipient": "",
    "contract_type": "StarterPackage",
    "comment": "",
    "contract_gea_service": "",
    "contract_gea_sales": "",
    "start_date": "",
    "contract_customer": "",
    "end_date": ""
  });
  const { data: edgeDevices, isLoading, isIdle } = spApiQueries.useGetEdgeDevices();
  const { data: orders } = spApiQueries.useGetOrders('order.contract eq null');
  const mutationFunction = spApiQueries.useCreateContract();
  const { data: selectedOrder } = spApiQueries.useGetOrder(contractFormData.order_id.toString(), contractFormData.pos_id.toString())

  const onSubmit = () => {
    mutationFunction.mutate(contractFormData, {
      onSuccess: () => {
        navigate(`/contracts/${contractFormData.order_id}/${contractFormData.pos_id}`)
      },
      onError(error) {
        toast.error(`ERROR: Could not create contract. ${formatError(error)}`)
      }
    })
  }

  var tableData: DictionaryListEntries = {
    "Order-Id": "",
    "Position-Id": "",
    "Contract Id": "",
    "Start Date": "",
    "End Date": "",
    "Estimated Edge Online Date": "",
    "Initial contract amount": "",
    "Initial contract currency": "",
    "Contract Type": "",
    "Contact Gea Service": "",
    "Contact Gea Sales": "",
    "Contact Customer (Email)": "",
    "Contact Admin Insight Partner": "",
    "Are network settings available": "",
    "Bonus recipient": "",
    "Comment": ""
  }
  if (edgeDevices && orders) {

    const handleChange = (ev: any) => {
      const target = ev.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      setContractFormData(formData => ({
        ...formData,
        [name]: value
      }))
      if (name === "order_id") {
        const pos_ids = getPosIdsFromOrderId(orders, value)
        setContractFormData(formData => ({
          ...formData,
          pos_id: pos_ids[0]
        }))
      }
    }

    const handleCurrencyChange = (currency: string) => {
      setContractFormData(formData => ({
        ...formData,
        initial_contract_amount_currency: currency
      }))
    }


    const handleContractTypeChange = (contract_type: ContractType) => {
      setContractFormData(formData => ({
        ...formData,
        contract_type: contract_type
      }))
    }

    const handleUserSelectChange = (name: string, value: string) => {
      setContractFormData(formData => ({
        ...formData,
        [name]: value
      }))
    }

    const handleOrderChange = (order: OrderGet) => {
      setContractFormData({
        ...contractFormData,
        order_id: order.order_id,
        pos_id: order.pos_id
      })
    }

    tableData = {
      "Order": <ObjectSelect<OrderGet>
        value={selectedOrder}
        options={orders}
        id={"order_id"}
        columns={["pos_id"]}
        onSelectChange={handleOrderChange} />,
      "Contract Id": <Input
        type="text"
        name="contract_id"
        value={contractFormData.contract_id}
        onChange={handleChange}
        className="p-1 pr-10"
      />,
      "Start Date": <Input
        type="date"
        name="start_date"
        value={contractFormData.start_date}
        onChange={handleChange}
        className="p-1"
      />,
      "End Date": <Input
        type="date"
        name="end_date"
        value={contractFormData.end_date}
        onChange={handleChange}
        className="p-1"
      />,
      "Estimated Edge Online Date": <Input
        type="date"
        name="estimated_edge_online_date"
        value={contractFormData.estimated_edge_online_date}
        onChange={handleChange}
        className="p-1"
      />,
      "Initial contract amount": <Input
        type="text"
        name="initial_contract_amount"
        value={contractFormData.initial_contract_amount}
        onChange={handleChange}
        className="p-1 pr-10"
      />,
      "Initial contract currency": <CurrencySelect
        currency={contractFormData.initial_contract_amount_currency} onCurrencyChange={handleCurrencyChange}
      />,
      "Contract Type": <ContractTypeSelect
        contractType={contractFormData.contract_type}
        onContractTypeChange={handleContractTypeChange} />,
      "Contact Gea Service": <UserSelect
        name="contract_gea_service"
        userMail={contractFormData.contract_gea_service}
        onMailChange={handleUserSelectChange} />,
      "Contact Gea Sales": <UserSelect
        name="contract_gea_sales"
        userMail={contractFormData.contract_gea_sales}
        onMailChange={handleUserSelectChange} />,
      "Contact Customer (Email)": <Input
        type="text"
        name="contract_customer"
        value={contractFormData.contract_customer}
        onChange={handleChange}
        className="p-1 pr-10"
      />,
      "Contact Admin Insight Partner": <UserSelect
        name="contract_customer_admin_insight_partner"
        userMail={contractFormData.contract_customer_admin_insight_partner || ""}
        onMailChange={handleUserSelectChange} />,
      "Are network settings available": <IndeterminateCheckbox
        name="is_network_setting_available"
        checked={contractFormData.is_network_setting_available}
        onChange={handleChange}
      />,
      "Bonus recipient": <UserSelect
        name="bonus_recipient"
        userMail={contractFormData.bonus_recipient}
        onMailChange={handleUserSelectChange} />,
      "Comment": <Input
        name="comment"
        value={contractFormData.comment}
        onChange={handleChange}
        className="p-1 pr-10"
      />
    }
  }

  return (
    <div>
      <Heading color={HeadingColor.Gray} processing={mutationFunction.isLoading}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={onSubmit}><PencilSquareIcon className="w-6 h-6 ml-5" />Save</HeadingButton>
        <HeadingButton onClick={() => navigate('/machines')}><XMarkIcon className="w-6 h-6 ml-5" />Cancel</HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} processing={isLoading || isIdle} />
    </div>
  )
}

function getPosIdsFromOrderId(orders: OrderGet[], order_id: number | null) {
  return orders.filter(order => order.order_id === order_id).map(order => order.pos_id)
}