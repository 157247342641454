import React, { useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../../components/Input/FormElements";
import Button from "../../../components/Input/Button";
import { InputLabel } from "../../../components/Input/InputLabel";
import { useMutation } from 'react-query';
import { DirectMethodResponse, easApi } from "../../../api/edgeAdministrationShell/easApi";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import SimpleTable from "../../../components/Table/SimpleTable";
import { Heading, HeadingColor } from "../../../components/Typography/Heading";
import { InformationCircleIcon } from "@heroicons/react/24/outline";


export default function RemoteControl() {
  const { deviceId } = useParams();
  const [formData, setFormData] = useState({
    networkDefinition: '',
    subnet: ''
  });

  const { mutate, isLoading, isSuccess, data } = useMutation<DirectMethodResponse<any>, AxiosError>({
    mutationFn: () => {
      const body = {
        "networkDefinition": formData.networkDefinition,
        "subnetMask": parseInt(formData.subnet),
        "ports": [
          4840,
          5900,
          9443,
          11169,
          21
        ]
      }
      return easApi.postDeviceNetworkDiscover(deviceId, body)
    },
    onError: (error) => {
      console.log(error)
      toast.error(`ERROR: Could not scan network: ${error.code} ${JSON.stringify(error.message)}`)
    }
  });

  const handleFormChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    mutate();
  }

  return (
    <>
    <ReadNetworkDefinition setFormData={setFormData}/>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-2">
          <InputLabel>Network Definition</InputLabel>
          <Input
            name="networkDefinition"
            type="text"
            value={formData.networkDefinition}
            onChange={handleFormChange}
          />
          <InputLabel>Subnet</InputLabel>
          <Input
            name="subnet"
            type="text"
            value={formData.subnet}
            onChange={handleFormChange}
          />
           <Button type="submit" processing={isLoading}>Scan Network (LAN3)</Button>
        </div >
      </form>
      {isSuccess && <EndpointList data={data}/>}
    </>
  );
}


function EndpointList({data}: {data: any}){
  const navigate = useNavigate();

  const tableData: any = []
  Object.keys(data.payload.endpoints).forEach(function(ep) {
    var services = data.payload.endpoints[ep];
    services.forEach(function(element: any) {
      var obj: any = {}
      obj.col1 = ep
      if (element.service === "OPC-UA Server")
        obj.col2 = <div>{element.service} 
        <Button onClick={() => navigate({
          pathname: '../opcua',
          search: `?${createSearchParams({
            endpoint: `opc.tcp://${ep}:4840`
        })}`
        })}>Browse</Button></div>

        else if (element.service === null) {
          obj.col2 = <div>FTP Server

        <Button onClick={() => navigate({
          pathname: '../ftp',
          search: `?${createSearchParams({
            endpoint: `${ep}`
        })}`
        })}>Browse</Button></div>
        }
      else
        obj.col2 = element.service
      tableData.push(obj)
    }); 
  });

  return(
    <div>
      <Heading color={HeadingColor.Gray}><InformationCircleIcon className="w-7 h-7 mr-1" />Discovered Endpoints</Heading>
      <SimpleTable tableData={tableData} />
    </div>
  )
}
// {item.type === 'dir' ? '📁' : '📄'} {item.name}

function ReadNetworkDefinition ({setFormData}: {setFormData: any}) {
  const { deviceId } = useParams();

  const { mutate, isLoading} = useMutation<DirectMethodResponse<any>, AxiosError>({
    mutationFn: () => {
      const body = {
        "methodName": "nm_show",
        "methodPayload": {
          "version": "1.0"
        }
      }
      return easApi.nmShow(deviceId, body)
    },
    onError: (error) => {
      toast.error(`ERROR: Could not get network definition: ${error.code} ${JSON.stringify(error.response?.data)}`)
    },
    onSuccess: (newData: any) => {
      let networkDefinition: string = ""
      let subnet: string = ""

      // check if network cable is attached
      if (newData?.payload.network.lan3.current_ip == null){
        toast.warning(`no network cable attached on LAN3`)
        return
      }

      // check type of output for LAN3 IP -> depnding on static or dhcp result can be string or list of strings
      if (typeof newData?.payload.network.lan3.current_ip == "string"){
        networkDefinition = newData?.payload.network.lan3.current_ip.split(".").slice(0,3).join(".") + ".0"
      }
      else {
        networkDefinition = newData?.payload.network.lan3.current_ip[0].toString().split(".").slice(0,3).join(".") + ".0"
      }

      // check type of output for LAN3 Subnet -> depnding on static or dhcp result can be string or list of strings
      if (typeof newData?.payload.network.lan3.current_subnet == "string"){
        subnet = newData?.payload.network.lan3.current_subnet.toString()
      }
      else {
        subnet = newData?.payload.network.lan3.current_subnet[0].toString()
      }

      setFormData({
      networkDefinition: networkDefinition,
      subnet: subnet
    })}
  });

  return <Button processing={isLoading} onClick={() => mutate()}>Read Network Definition (LAN3)</Button>
}