import { AxiosError } from "axios";
import { ValidationError } from "../api/starterPackages/interfaces/spApiError";

export function formatError(error: AxiosError) {
    if (error.response && error.response.status === 422) {
        return formatValidationError(error.response.data as ValidationError)
    }
    return error.message
}


function formatValidationError(errorSchema: ValidationError): string {
  const errorMessages: string[] = [];

  errorSchema.detail.forEach((error) => {
      error.loc.shift()
      const location = error.loc.join(" -> ");
      let message = `Error in ${location}: ${error.msg}\n`;

      if (error.ctx) {
          if (error.ctx.error) {
              message += ` (${error.ctx.error})`;
          }
          if (error.ctx.pattern) {
              message += ` (Expected pattern: ${error.ctx.pattern})`;
          }
      }
      errorMessages.push(message);
  });

  return errorMessages.join("\n");
}