import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// MSAL imports
import { AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { easApiLoginRequest, msalConfig } from "./config/authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

// Sentry imports
import * as Sentry from "@sentry/react";

// Sentry init
import { sentryConfig } from './config/sentryConfig';

Sentry.init(sentryConfig);

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={easApiLoginRequest}>
          <App />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.StrictMode>
  );
});
