import axios from "axios";

export const rcApiInstance = axios.create();

const checkEndpointStatus = async (service: string, deviceId: string | undefined) => {
  const { data } = await rcApiInstance.post("/checkEndpoint", { service: service, id: deviceId });
  return data;
}

const addRCEndpoint = async (service: string, deviceId: string | undefined) => {
  const { data } = await rcApiInstance.post("/addRCEndpoint", { service: service, id: deviceId });
  return data;
}

export const rcApi = {
  checkEndpointStatus,
  addRCEndpoint
}