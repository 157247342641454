import { Dispatch, SetStateAction, useState } from 'react';
import Badge, { BadgeColor } from '../../components/Typography/Badge';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../components/Input/FormElements';
import { Table, TBody, THead, TH, TD, TR } from '../../components/Table/TableComponents';
import { spApiQueries } from '../../api/starterPackages/starterPackagesQueries';
import { OrderGet } from '../../api/starterPackages/interfaces/orders';

export default function OrderList() {
  const [filter, setFilter] = useState('');
  const { isLoading, isError, isIdle, data: orders, error } = spApiQueries.useGetOrders();

  if (isLoading || isIdle)
    return <div className="w-full">Loading</div>

  if (isError)
    return <div className="w-full">Error: {error.message} {error.response?.data?.detail}</div>

  const filteredOrders = orders.filter((order) =>
    order.order_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
    order.pos_id.toString().includes(filter.toLowerCase()) ||
    order.type.toLowerCase().includes(filter.toLowerCase()) ||
    order.material.toLowerCase().includes(filter.toLowerCase()) ||
    order.customer_name.toLowerCase().includes(filter.toLowerCase()) ||
    order.edge_device?.edge_device_id.includes(filter.toLowerCase())
  );
  
  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <OrderTable orders={filteredOrders} filter={filter} setFilter={setFilter} />
      </div>
      <div className="block @2xl:hidden">
        <OrderCards orders={filteredOrders} filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}


// Used for both types of displaying
interface OrderListProps {
  orders: OrderGet[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}
function OrderTable({ orders, filter, setFilter }: OrderListProps) {
  const navigate = useNavigate();
  const { orderId, posId } = useParams();

  const orderItems = orders.map((order) =>
    <TR onClick={() => navigate(`/orders/${order.order_id}/${order.pos_id}`)} className={`${orderId === order.order_id.toString() && posId === order.pos_id.toString() ? "bg-gray" : "bg-white hover:bg-gray-100"}`} key={`${order.order_id}-${order.pos_id}`}>
      <TD>{order.order_id}</TD>
      <TD>{order.pos_id}</TD>
      <TD>{order.type}</TD>
      <TD>{order.material}</TD>
      <TD><Badge color={order.contract ? BadgeColor.Green : BadgeColor.Red}>{order.contract ? "Yes" : "No"}</Badge></TD>
      <TD>{order.customer_name}</TD>
      <TD>{order.machines.length}</TD>
      <TD>{order.edge_device?.edge_device_id}</TD>
    </TR>
  );

  return (
    <div className="rounded-sm">
      <Table>
        <THead>
          <TR>
            <TH>Order Id</TH>
            <TH>Position Id</TH>
            <TH>Type</TH>
            <TH>Material</TH>
            <TH>Contract</TH>
            <TH>Customer</TH>
            <TH># Machines</TH>
            <TH>
              <div className="flex">
                <div className="grow">Edge Device</div>
                <Input
                  type="text"
                  name="filter"
                  value={filter}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
                  placeholder="Search"
                  className="text-sm text-black -my-1 p-1"
                />
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{orderItems}</TBody>
      </Table>
    </div>
  )
}

function OrderCards({ orders, filter, setFilter }: OrderListProps) {
  const { orderId, posId } = useParams();
  const navigate = useNavigate();

  const deviceItems = orders.map((order) =>
    <div
      onClick={() => navigate(`/orders/${order.order_id}/${order.pos_id}`)}
      key={`${order.order_id}-${order.pos_id}`}
      className={`p-2 rounded-sm border border-1 ${orderId === order.order_id.toString() && posId === order.pos_id.toString() ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge>{order.order_id}</Badge>
        <Badge>{order.pos_id}</Badge>
      </div>
      <div className="font-medium truncate">{order.customer_name}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium">
        <Input
          type="text"
          name="filter"
          value={filter}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
          placeholder="Search"
        />
      </div>
      {deviceItems}
    </div>
  )
}