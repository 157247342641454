import { BrowseResult, Subscription } from "../../api/edgeAdministrationShell/easApiHooks";
import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { NodeIdTreeItem } from "./NodeIdTreeItem";

interface NodeIdReference {
  referenceTypeId: string,
  isForward: boolean,
  nodeId: string
  browseName: {
    namespaceIndex: number,
    name: string
  },
  displayName: {
    text: string
  },
  nodeClass: string,
}

interface NodeIdTreeLayerParams {
  nodeIdReferences: NodeIdReference[],
  depth: number,
  subscriptions: Subscription[],
  toggleNodeIdFromSubscription: (nodeId: string, subscriptionName: string) => void,
  useBrowseNodeIds: (nodeIds: NodeIdReference[]) => UseQueryResult<BrowseResult[], AxiosError>,
}

/**
 * Recursive Component for displaying and browsing through the OPCUA Node Id Tree.
 * It requires parameters for aquiring the connection and a nodeIdReference for the root node.
 * @param param0 
 * @returns 
 */
export function NodeIdTreeLayer({ nodeIdReferences, depth, useBrowseNodeIds, subscriptions, toggleNodeIdFromSubscription }: NodeIdTreeLayerParams) {

  const { data, status } = useBrowseNodeIds(nodeIdReferences);

  const layer = nodeIdReferences.map((nodeIdReference, index) => {
    let childNodeIdReferences: NodeIdReference[] | undefined = undefined;
    if (data)
      childNodeIdReferences = data[index].references;
    return (
      <NodeIdTreeItem
        key={nodeIdReference.nodeId}
        nodeIdReference={nodeIdReference}
        depth={depth}
        subscriptions={subscriptions}
        toggleNodeIdFromSubscription={toggleNodeIdFromSubscription}
        useBrowseNodeIds={useBrowseNodeIds}
        childNodeIdReferences={childNodeIdReferences}
        childNodeIdReferenceStatus={status}
      />)
  });

  return <>{layer}</>;
}