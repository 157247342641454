import { useParams } from "react-router-dom";
import { Heading, HeadingColor } from "../../components/Typography/Heading";
import { InformationCircleIcon} from "@heroicons/react/24/outline";
import { spApiQueries } from "../../api/starterPackages/starterPackagesQueries";
import DictionaryList, { DictionaryListEntries } from "../../components/Table/DictionaryList";


var tableData: DictionaryListEntries = {
  "Order": "",
  "Customer": "",
  "Order type": "",
  "Type": "",
  "Material": "",
  "Amount": "",
  "Unit": "",
  "Locale": "",
  "Created on": "",
  "Last updated on": "",
  "Last updated by": ""
}

export default function OrderDetail() {
  const { orderId, posId } = useParams();
  const { data: order, isLoading, isIdle, isFetching, isError, error } = spApiQueries.useGetOrder(orderId, posId);

  if (order) {
    tableData = {
      "Order": `${order.order_id} - ${order.pos_id}`,
      "Customer": order.customer_name,
      "Order type": order.order_type,
      "Type": order.type,
      "Material": order.material,
      "Amount": order.amount,
      "Unit": order.unit,
      "Locale": order.locale,
      "Created on": order.order_creation
    }
  }

  const errorMessage = isError ? `${error.message}` : undefined

  return (
    <div>
      <Heading processing={isFetching} color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
      </Heading>
      <DictionaryList dictionary={tableData} processing={isLoading || isIdle} error={errorMessage} />
    </div>
  )
}