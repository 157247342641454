import { Dispatch, SetStateAction, useState } from 'react';
import Badge from '../../components/Typography/Badge';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../components/Input/FormElements';
import { Table, TBody, THead, TH, TD, TR } from '../../components/Table/TableComponents';
import { spApiQueries } from '../../api/starterPackages/starterPackagesQueries';
import { HeadingButton } from '../../components/Typography/Heading';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { ContractGet } from '../../api/starterPackages/interfaces/contracts';
import AuthGuard from '../../components/Auth/AuthGuard';

export default function ContractList() {
  const [filter, setFilter] = useState('');
  const { isLoading, isError, isIdle, data, error } = spApiQueries.useGetContracts();

  if (isLoading || isIdle)
    return <div>Loading</div>

  if (isError)
    return <div className="w-full">Error: {error.message} {error.response?.data.detail}</div>

  const filteredData = data.filter((contract) =>
    contract.contract_id.toLowerCase().includes(filter.toLowerCase()) ||
    contract.order.customer_name?.toLowerCase().includes(filter.toLowerCase()) ||
    contract.order_id.toString().includes(filter.toLowerCase())
  );
  
  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <ContractTable contracts={filteredData} filter={filter} setFilter={setFilter} />
      </div>
      <div className="block @2xl:hidden">
        <ContractCards contracts={filteredData} filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}


// Used for both types of displaying
interface ContractListProps {
  contracts: ContractGet[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}
function ContractTable({ contracts, filter, setFilter }: ContractListProps) {
  const navigate = useNavigate();
  const { contractId } = useParams();

  const deviceItems = contracts.map((contract) =>
    <TR onClick={() => navigate(`/contracts/${contract.order_id}/${contract.pos_id}`)} className={`${contractId === contract.contract_id ? "bg-gray" : "bg-white hover:bg-gray-100"}`} key={contract.contract_id}>
      <TD>{contract.contract_id}</TD>
      <TD><Badge>{contract.order.customer_name}</Badge></TD>
      <TD><Badge>{contract.order_id} - {contract.pos_id}</Badge></TD>
      <TD><Badge>{contract.machines.length}</Badge></TD>
    </TR>
  );

  return (
    <div className="rounded-sm">
      <Table>
        <THead>
          <TR>
            <TH>Contract Id</TH>
            <TH>Customer</TH>
            <TH>Order</TH>
            <TH>
              <div className="flex">
                <div className="grow"># Machines</div>
                <AuthGuard requiredRoles={["user.admin", "contract.editor"]} >
                  <HeadingButton onClick={() => navigate(`/contracts/create`)} className="mr-5"><PlusCircleIcon className="w-6 h-6" />Add Contract</HeadingButton>
                </AuthGuard>
                <Input
                  type="text"
                  name="filter"
                  value={filter}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
                  placeholder="Search"
                  className="text-sm text-black -my-1 p-1"
                />
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{deviceItems}</TBody>
      </Table>
    </div>
  )
}

function ContractCards({ contracts, filter, setFilter }: ContractListProps) {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const deviceItems = contracts.map((contract) =>
    <div
      onClick={() => navigate(`/contracts/${contract.order_id}/${contract.pos_id}`)}
      key={contract.contract_id}
      className={`p-2 rounded-sm border border-1 ${deviceId === contract.contract_id ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge>{contract.contract_id}</Badge>
        <Badge>{contract.order.customer_name}</Badge>
      </div>
      <div className="font-medium truncate">{contract.order_id}</div>
      <div className="truncate">{contract.pos_id}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium">
        <Input
          type="text"
          name="filter"
          value={filter}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
          placeholder="Search"
        />
      </div>
      {deviceItems}
    </div>
  )
}