import { Dispatch, SetStateAction, useState } from 'react';
import Badge, { BadgeColor } from '../../components/Typography/Badge';
import { useNavigate, useParams } from 'react-router-dom';
import { easApi } from '../../api/edgeAdministrationShell/easApi';
import { useQuery } from 'react-query';
import { Input } from '../../components/Input/FormElements';
import { Table, TBody, THead, TH, TD, TR } from '../../components/Table/TableComponents';

interface DeviceData {
  deviceName: string
  deviceStatus?: string
  customer?: any
  deployment?: any
  description?: any
}

export default function DeviceList() {
  const [filter, setFilter] = useState('');
  const { isLoading, isError, isIdle, data, error } = useQuery<DeviceData[], Error>({
    queryKey: ['getDevices'],
    queryFn: () => easApi.getDevices()
  })

  if (isLoading || isIdle)
    return <div>Loading</div>

  if (isError)
    return <div>Error: {error.message}</div>

  const filteredData = data.filter((device) =>
    device.deviceName.toLowerCase().includes(filter.toLowerCase()) ||
    device.customer?.toLowerCase().includes(filter.toLowerCase()) ||
    device.deployment?.toLowerCase().includes(filter.toLowerCase()) ||
    device.description?.toLowerCase().includes(filter.toLowerCase()) ||
    (device.deviceStatus === "Connected" && 'online'.includes(filter.toLowerCase())) ||
    (device.deviceStatus === "Disconnected" && 'offline'.includes(filter.toLowerCase()))
  )
  
  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <DeviceTable devices={filteredData} filter={filter} setFilter={setFilter} />
      </div>
      <div className="block @2xl:hidden">
        <DeviceCards devices={filteredData} filter={filter} setFilter={setFilter} />
      </div>
    </div>
  )
}


// Used for both types of displaying
interface DeviceListProps {
  devices: DeviceData[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}
function DeviceTable({ devices, filter, setFilter }: DeviceListProps) {
  const navigate = useNavigate();
  const { deviceId } = useParams();

  const deviceItems = devices.map((device) =>
    <TR onClick={() => navigate(`/devices/${device.deviceName}`)} className={`${deviceId === device.deviceName ? "bg-gray" : "hover:bg-gray-100"}`} key={device.deviceName}>
      <TD>
        <Badge color={device.deviceStatus === "Connected" ? BadgeColor.Green : BadgeColor.Red}>
          {device.deviceStatus === "Connected" ? "online" : "offline"}
        </Badge>
      </TD>
      <TD>{device.deviceName}</TD>
      <TD><Badge>{device.deployment}</Badge></TD>
      <TD><Badge>{device.customer}</Badge></TD>
      <TD>{device.description}</TD>
    </TR>
  );

  return (
    <div className="rounded-sm">
      <Table>
        <THead>
          <TR>
            <TH>Status</TH>
            <TH>Device-ID</TH>
            <TH>Deployment</TH>
            <TH>Customer</TH>
            <TH>
              <div className="flex">
                <div className="grow">Description</div>
                <Input
                  type="text"
                  name="filter"
                  value={filter}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
                  placeholder="Search"
                  className="text-sm text-black -my-1 p-1"
                />
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{deviceItems}</TBody>
      </Table>
    </div>
  )
}

function DeviceCards({ devices, filter, setFilter }: DeviceListProps) {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const deviceItems = devices.map((device) =>
    <div
      onClick={() => navigate(`/devices/${device.deviceName}`)}
      key={device.deviceName}
      className={`p-2 rounded-sm border border-1 ${deviceId === device.deviceName ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge color={device.deviceStatus === "Connected" ? BadgeColor.Green : BadgeColor.Red}>
          {device.deviceStatus === "Connected" ? "online" : "offline"}
        </Badge>
        <Badge>{device.deployment}</Badge>
        <Badge>{device.customer}</Badge>
      </div>
      <div className="font-medium truncate">{device.deviceName}</div>
      <div className="truncate">{device.description}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium">
        <Input
          type="text"
          name="filter"
          value={filter}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setFilter(ev.target.value)}
          placeholder="Search"
        />
      </div>
      {deviceItems}
    </div>
  )

}
