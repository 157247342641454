import { DetailedHTMLProps, OptionHTMLAttributes, SelectHTMLAttributes, TextareaHTMLAttributes, HTMLProps, useRef, useEffect } from "react";
import ReactSelect, { GroupBase, Props } from 'react-select';

interface InputProps extends HTMLProps<HTMLInputElement> {
  className?: string;
}

interface TextAreaInputProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  className?: string;
}

export function Input({ className, disabled, ...props }: InputProps) {

  return (
    <input
      {...props}
      className={`${(disabled) ? "bg-slate-200" : "bg-slate-50"} rounded-sm focus:outline-border focus:ring-vibrant-blue ${className ? className : ""}`}
    />
  )
}

export function IndeterminateCheckbox({ indeterminate, className = '', ...rest }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

export function TextAreaInput({ className, disabled, ...props }: TextAreaInputProps) {
  return (
    <textarea
      {...props}
      className={`${(disabled) ? "bg-slate-200" : "bg-slate-50"} rounded-sm focus:outline-border focus:ring-vibrant-blue min-h-[42px] ${className}`}
    />
  )
}
export function SearchableSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      {...props}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          'input:focus': {
            boxShadow: 'none',
          },
        }),
        control: (base) => ({
          ...base,
          'minHeight': '34px'
        })
      }}
      classNames={{
        container: () => "bg-slate-50 rounded-sm border border-gray-500 focus:outline-border focus:ring-vibrant-blue",
        control: () => "min-h-6",
        menu: () => "bg-slate-50 border border-gray-500 mt-2 px-1",
        valueContainer: () => "px-1"
      }} />
  )
}

export function Select({ className, ...props }: DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  return (
    <select {...props} className={`bg-slate-50 rounded-sm focus:outline-border focus:ring-vibrant-blue pr-10 ${className}`}>
      {props.children}
    </select>
  )
}

export function Option({ ...props }: DetailedHTMLProps<OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>) {
  return (
    <option  {...props}>
      {props.children}
    </option>
  )
}