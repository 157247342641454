import React, { DetailedHTMLProps, HTMLAttributes, TableHTMLAttributes, TdHTMLAttributes } from "react"

export function Table({children, ...props}: DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>) {
    return <table className="w-full rounded-sm relative" {...props}>{children}</table>
}

export function TR({children, className, ...props}: DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) {
    return <tr className={`even:bg-slate-50 ${className}`} {...props}>{children}</tr>
}

export function THead({children, ...props}: DetailedHTMLProps<HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) {
    return <thead className="sticky top-0 bg-vibrant-blue text-white text-left" {...props}>{children}</thead>
}

export function TBody({children, ...props}: DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) {
    return <tbody className="divide-y divide-solid bg-white" {...props}>{children}</tbody>
}

export function TD({children, ...props}: DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) {
    return <td className="p-2" {...props}>{children}</td>
}

export function TH({children, ...props}: DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) {
    return <th className="p-2" {...props}>{children}</th>
}