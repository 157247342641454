import { useMutation } from "react-query";
import Button from "../../../components/Input/Button";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function DirectMethods({ moduleName }: { moduleName: string }) {

  return (
    <div className="grid grid-cols space-y-2 mt-2">
      <RestartModule moduleName={moduleName} />
      {moduleName.includes('gea-app-opcua-client') && <CreateInfluxBucket />}
      {moduleName.includes('gea-app-opcua-client') && <GetInvalidNodeIds moduleName={moduleName} />}
      {moduleName.includes('gea-app-opcua-client') && <ReadNodeId moduleName={moduleName} />}
    </div>
  )
}

function RestartModule({ moduleName }: { moduleName: string }) {
  const { deviceId } = useParams();
  
  //edgeAgent and HedgeHub have leading $-signs which need to be removed for edgeAgents RestartModule method
  if (moduleName == "$edgeAgent"){
    moduleName = "edgeAgent"
  }
  if (moduleName == "$edgeHub"){
    moduleName = "edgeHub"
  }
  const { mutate } = useMutation({
    mutationFn: () => {
      const payload = {
        "methodName": "RestartModule",
        "methodPayload": {
          "schemaVersion": "1.0",
          "id": moduleName
        }
      }
      return easApi.invokeDirectMethod(deviceId, "$edgeAgent", payload)
    },
    onSuccess: () => {
      toast.success(`Success: Module ${moduleName} restarted on Device ${deviceId}`)
    },
    onError: () => {
      // edgeAgent always throws an error -> also if it gets directly restarted from azure portal
      if (moduleName == "edgeAgent"){
        toast.info(`Restart of IoTEdge Runtime initiated on Device ${deviceId}`)
      }
      else{
        toast.error(`Error: Module ${moduleName} was not restarted on Device ${deviceId}`)
      }
    }
  })
  return <Button onClick={() => mutate()}>Restart Module</Button>
}



function GetInvalidNodeIds({ moduleName }: { moduleName: string }) {
  const { deviceId } = useParams();

  const { mutate, data, isSuccess, isLoading } = useMutation({
    mutationFn: () => {
      const payload = {
        "methodName": "getInvalidNodeIds",
        "methodPayload": {
          "version": "1.0"
        }
      }
      return easApi.invokeDirectMethod(deviceId, moduleName, payload)
    },
    onError: () => {
      toast.error(`Error: Could not get invalid Node Ids from ${moduleName} on Device ${deviceId}`)
    }
  });

  return (
    <>
      <Button processing={isLoading} onClick={() => mutate()}>Get invalid Node Ids</Button>
      {isSuccess && JSON.stringify(data.payload, null, 4)}
    </>
  )
}


function ReadNodeId({ moduleName }: { moduleName: string }) {
  const { deviceId } = useParams();
  const [nodeId, setNodeId] = useState<string>("");
  const [formattedResult, setFormattedResult] = useState<string | null>(null);


  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      const payload = {
        "methodName": "singleRead",
        "methodPayload": {
          "nodeId": nodeId
        }
      }
      return easApi.invokeDirectMethod(deviceId, moduleName, payload)
    },
    onError: () => {
      toast.error(`Error: Could not read node-id ${nodeId} on Device ${deviceId}`)
    },
    onSuccess: (result) => {
      setFormattedResult(JSON.stringify(result.payload, null, 4))
    }
  });

  return (
    <>
      <Button processing={isLoading} onClick={() => mutate()}>Read Node-ID</Button>
      
      <input 
        placeholder="enter node id here"
        value={nodeId}
        onChange={(e) => setNodeId(e.target.value)} // Update the nodeId state
      />
      <pre>{formattedResult}</pre>
    </>
  )
}


function CreateInfluxBucket () {
  const { deviceId } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      let resp = await easApi.postInfluxBucketCreate(deviceId)
      return resp
    },

    onError: (error: any) => {
        console.log(error)
        if (error.response.status === 409){
            toast.info(`Bucket for device ${deviceId} already exists`)
        }
        else{
            toast.error(`ERROR: Could not acreate influx bucket: ${error.code} ${JSON.stringify(error.response?.data)}`)
        }
    },

    onSuccess: (resp: any) => {
      if (resp.showSuccess){
        toast.success(`Influx bucket created: ${JSON.stringify(resp.data)}`)
      }
    }
  });

  return <>
    <Button processing={isLoading} onClick={() => mutate()}>Create Influx Database</Button>
    </>
}