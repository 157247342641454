import { ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Spinner from "../../components/Misc/Spinner";
import { IndeterminateCheckbox } from "../../components/Input/FormElements";
import { useVisibleItemsContext } from "./TreeContext";
import { useEffect, useState } from "react";
import { BrowseResult, Subscription } from "../../api/edgeAdministrationShell/easApiHooks";
import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { NodeIdTreeLayer } from "./NodeIdTreeLayer";
import { useNodeIdValueContext } from "./NodeIdValueContext";

export interface NodeIdReference {
  referenceTypeId: string,
  isForward: boolean,
  nodeId: string
  browseName: {
    namespaceIndex: number,
    name: string
  },
  displayName: {
    text: string
  },
  nodeClass: string,
}

interface NodeIdTreeItemParams {
  nodeIdReference: NodeIdReference,
  depth: number,
  subscriptions: Subscription[],
  toggleNodeIdFromSubscription: (nodeId: string, subscriptionName: string) => void,
  useBrowseNodeIds: (nodeIds: NodeIdReference[]) => UseQueryResult<BrowseResult[], AxiosError>,
  childNodeIdReferences: NodeIdReference[] | undefined,
  childNodeIdReferenceStatus: "idle" | "error" | "loading" | "success"
}

/**
 * Recursive Component for displaying and browsing through the OPCUA Node Id Tree.
 * It requires parameters for aquiring the connection and a nodeIdReference for the root node.
 * @param param0 
 * @returns 
 */
export function NodeIdTreeItem({ nodeIdReference, depth, subscriptions, toggleNodeIdFromSubscription, useBrowseNodeIds, childNodeIdReferences, childNodeIdReferenceStatus }: NodeIdTreeItemParams) {
  const [expanded, setExpanded] = useState(false);
  const { addVisibleTreeItem, removeVisibleTreeItem } = useVisibleItemsContext();
  const { nodeIdValues } = useNodeIdValueContext();

  // Adds and removes nodeIds from a list which depicts the visible nodeIds
  useEffect(() => {
    if (nodeIdReference.nodeClass === 'Variable') {
      addVisibleTreeItem(nodeIdReference.nodeId);
    }
    return () => {
      if (nodeIdReference.nodeClass === 'Variable')
        removeVisibleTreeItem(nodeIdReference.nodeId);
    };
  }, [addVisibleTreeItem, removeVisibleTreeItem, nodeIdReference.nodeId, nodeIdReference.nodeClass]);

  const expansionIndicator = () => {
    if (childNodeIdReferenceStatus === 'loading' || childNodeIdReferenceStatus === 'idle')
      return <Spinner processing={true} />
    if (childNodeIdReferenceStatus === 'error')
      return <ExclamationTriangleIcon className="h-4 w-4" />
    return childNodeIdReferences && childNodeIdReferences.length > 0 ? (expanded ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4" />) : <span className="h-4 w-4"></span>
  }
  let childLayer = <></>;
  if (childNodeIdReferences) {
    childLayer = <NodeIdTreeLayer
      nodeIdReferences={childNodeIdReferences}
      depth={depth + 1}
      subscriptions={subscriptions}
      toggleNodeIdFromSubscription={toggleNodeIdFromSubscription}
      useBrowseNodeIds={useBrowseNodeIds} />
  }

  return (
    <>
      <tr>
        <td className="p-2" style={{ paddingLeft: `${depth}rem` }}>
          <button onClick={() => setExpanded(!expanded)} className="flex w-full items-center">
            {expansionIndicator()}
            <span className="truncate">{nodeIdReference.displayName.text}</span>
          </button>
        </td>
        {subscriptions.map((subscription, index) => {
          return (
            <td
              className="truncate"
              key={index}>
              <IndeterminateCheckbox
                checked={Array.isArray(subscription.nodeIds) ? subscription.nodeIds.includes(nodeIdReference.nodeId) : nodeIdReference.nodeId in subscription.nodeIds}
                onChange={() => toggleNodeIdFromSubscription(nodeIdReference.nodeId, subscription.subscriptionName)}
                disabled
              />
            </td>)
        }
        )}
        <td className="truncate">{nodeIdValues && JSON.stringify(nodeIdValues[nodeIdReference.nodeId])}</td>
      </tr>
      {expanded && childLayer}
    </>
  )
}