import { SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";
import { ReactNode } from 'react';

interface SelectProps<T> {
  value: T | undefined,
  options: T[] | undefined,
  id: keyof T,
  columns: (keyof T)[],
  onSelectChange: (object: T) => void,
  noOptionsMessage?: ((obj: { inputValue: string; }) => ReactNode)
}


export function ObjectSelect<T>({ value, options, onSelectChange, id, columns, noOptionsMessage }: SelectProps<T>) {
  const onChange = (newValue: SingleValue<T>) => {
    if (newValue !== null) {
      onSelectChange(newValue);
    }
  }

  const formatOptionLabel = (object: T) => {
    return (
      <div className={`grid grid-cols-${columns.length + 1}`}>
        <div className="truncate hidden md:block">{object[id] as string}</div>
        {columns.map((column, index) => 
          <div className="truncate hidden md:block" key={index}>{object[column] as string}</div>
        )}
      </div>
    )
  }

  return (
    <SearchableSelect
      options={options}
      value={value}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={value => value[id] as string}
      isLoading={!options}
      onChange={onChange}
      isClearable={true}
      noOptionsMessage={noOptionsMessage} />
  )
}