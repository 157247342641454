import { SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";
import { spApiQueries } from "../../../api/starterPackages/starterPackagesQueries";
import getSymbolFromCurrency from "currency-symbol-map";

interface EdgeDeviceSelectProps {
  currency: string,
  onCurrencyChange: (mail: string) => void
}

export function CurrencySelect({ currency, onCurrencyChange }: EdgeDeviceSelectProps) {
  const { data: currencyCodes, isLoading: currencyCodesIsLoading } = spApiQueries.useGetCurrencyCodes();

  const onHandleChange = (newValue: SingleValue<any>) => {
    onCurrencyChange(newValue ? newValue.value : "")
  }

  const value = {
    label: formatCurrency(currency),
    value: currency
  }

  const currencyCodesOptions = currencyCodes ? currencyCodes.map(currencyCode => ({
      label: formatCurrency(currencyCode),
      value: currencyCode
  })) : undefined

  return (
    <SearchableSelect
      options={currencyCodesOptions}
      value={value}
      isLoading={currencyCodesIsLoading}
      onChange={onHandleChange}
      isClearable={false}
    />
  )
}

function formatCurrency(currencyCode: string) {
  const currencySymbol = getSymbolFromCurrency(currencyCode)
  if (currencySymbol)
    return `${currencyCode} - ${currencySymbol}`
  return currencySymbol
}