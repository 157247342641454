import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import Badge, { BadgeColor } from "../../../components/Typography/Badge";
import { ChevronRightIcon, LinkIcon, TrashIcon, PencilSquareIcon, CheckIcon } from "@heroicons/react/24/outline";
import Modal, { ModalState } from "../../../components/Modal/Modal";
import { Tab } from "@headlessui/react";
import ModuleLogs from "./ModuleLogs";
import ModuleConfig from "./ModuleConfig";
import DirectMethods from "./DirectMethods";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";
import { useQuery, useQueryClient } from 'react-query';
import { easApiHooks, ModuleData } from "../../../api/edgeAdministrationShell/easApiHooks";
import { Table, THead, TH, TBody, TR, TD } from "../../../components/Table/TableComponents";
import { toast } from 'react-toastify';


export default function ModuleList() {
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    children: <></>
  });
  const [confirmDeleteState, setConfirmDeleteState] = useState({
    isOpen: false,
    moduleId: ""
  });
  const { deviceId } = useParams();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [newModuleName, setNewModuleName] = useState<string>("");
  const [disabledModules, setDisabledModules] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { data: modules } = easApiHooks.useGetModules(deviceId);

  const { isLoading, isError, isIdle, data: moduleData, error: statusError} = useQuery<ModuleData[], Error, ModuleData[]>({
    queryKey: ['getConfigStatus', deviceId, modules],
    queryFn: async () => {
      const moduleNames = modules?.map((module) => module.moduleId);                       
      const configStatus = await easApi.getConfigStatus(deviceId, { modules: moduleNames });  
      const moduleData: any = modules?.map((module) => {                                      
        return {
          ...module,
          appMessage: configStatus[module.moduleId].appMessage || undefined,
          appStatus: configStatus[module.moduleId].appStatus || undefined,
          confStatus: configStatus[module.moduleId].confStatus || undefined,
          desiredConfId: configStatus[module.moduleId].desiredConfId || undefined,
          reportedConfId: configStatus[module.moduleId].reportedConfId || undefined
        }
      });
      return moduleData;
    },
    enabled: !!modules,
    refetchInterval: 5000,
  });

  if (isLoading || isIdle) return <div>Loading...</div>
  if (isError) return <div>Error: {statusError.message}</div>

  const handleDelete = async (moduleId: any) => {
    try {
      await easApi.postDeploymentDeleteModule(deviceId, moduleId);
      toast.info(`Marked module ${moduleId} for deletion`)
      queryClient.invalidateQueries(['getModules', deviceId]);
    } catch (error) {
      toast.error(`Failed to delete module ${moduleId}`)
    }
  };

  const handleRename = async (index: number, moduleId: string, newName: string) => {
    try {
      await easApi.postDeploymentModuleNames(deviceId, { [moduleId]: newName });
      setEditingIndex(null);
      setNewModuleName("");
      toast.success(`Renamed module ${moduleId} to ${newName}`)
      queryClient.invalidateQueries(['getModules', deviceId]);
    } catch (error) {
      toast.error(`Failed to rename module ${moduleId}`)
    }
  };

  const moduleRows = moduleData.map((module, index) => (
    <TR onClick={() => setModalState({
      children: <ModuleModal module={module} />,
      title: <div className="flex items-center">{deviceId}<ChevronRightIcon className="w-4 h-4" /> {module.moduleName}</div>,
      isOpen: true
    })} key={index}>
      <TD>
        <Badge color={module.connectionState === "Connected" ? BadgeColor.Green : BadgeColor.Red}>
          {module.connectionState === "Connected" ? <LinkIcon className="w-3 h-3" /> : ""}
        </Badge>
      </TD>
      <TD>
        <Badge color={
          (module.moduleName === "$edgeAgent" && module.status === "runtime_online") ? BadgeColor.Green :
          (module.moduleName === "$edgeAgent" && module.status === "runtime_offline") ? BadgeColor.Yellow :
          (module.moduleName === "$edgeAgent" && module.status === null) ? BadgeColor.Green :
          (module.moduleName === "$edgeHub" && module.status === "runtime_online") ? BadgeColor.Green :
          (module.moduleName === "$edgeHub" && module.status === "runtime_offline") ? BadgeColor.Yellow :
          (module.moduleName === "$edgeHub" && module.status === null) ? BadgeColor.Green :
          (module.status === "running") ? BadgeColor.Green :
          (module.status === "backoff") ? BadgeColor.Red : 
          (module.status === "failed") ? BadgeColor.Red : 
          (module.status === "deploy_scheduled") ? BadgeColor.Purple : 
          (module.status === "delete_scheduled") ? BadgeColor.Yellow : 
          (module.status === "running:grey" || module.status === "backoff:grey"  || module.status === "failed:grey") ? BadgeColor.Gray : BadgeColor.Gray
        }>
          {module.status === "running" && "RUNNING"}
          {module.status === "backoff" && "BACKOFF"}
          {module.status === "failed" && "FAILED"}
          {module.status === "deploy_scheduled" && "WAIT FOR DEPLOYMENT"}
          {module.status === "delete_scheduled" && "WAIT FOR DELETION"}
          {module.status === "failed:grey" && "FAILED"}
          {module.status === "running:grey" && "RUNNING"}
          {module.status === "backoff:grey" && "BACKOFF"}
          {module.status === "runtime_online" && "RUNTIME ONLINE"}
          {module.status === "runtime_offline" && "RUNTIME OFFLINE"}
          {module.status === null && "UNKNOWN"}
        </Badge>
      </TD>
      <TD>
        {(() => {
          switch (module.confStatus) {
            case 'NO_CONFIG':
              return <Badge color={BadgeColor.Gray}>NTS</Badge>;
            case 'OK':
              return <Badge color={BadgeColor.Green}>SYNC</Badge>;
            case 'PENDING':
              return <Badge color={BadgeColor.Yellow}>PENDING</Badge>;
            case 'INITIAL_PENDING':
              return <Badge color={BadgeColor.Purple}>INITIAL_PENDING</Badge>;
            default:
              return <Badge color={BadgeColor.Purple}>{module.confStatus}</Badge>;
          }
        })()}
      </TD>
      <TD>
        {(() => {
          switch (module.appStatus) {
            case 'OK':
              return <Badge color={BadgeColor.Green}>CONFIG OK</Badge>;
            case 'ERROR':
              return <Badge color={BadgeColor.Red}>CONFIG ERROR</Badge>;
            case 'NO_STATUS':
              return <Badge color={BadgeColor.Gray}>NO CONFIG</Badge>;
            default:
              return <Badge color={BadgeColor.Purple}>{module.appStatus}</Badge>;
          }
        })()}
      </TD>
      <TD className="flex items-center">
        {editingIndex === index ? (
          <>
            <input
              type="text"
              value={newModuleName}
              onChange={(e) => setNewModuleName(e.target.value)}
              className="border rounded p-1"
              onClick={(e) => e.stopPropagation()}
            />
            <CheckIcon
              className="w-4 h-4 ml-2 text-green-500 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleRename(index, module.moduleId, newModuleName);
              }}
            />
          </>
        ) : (
          <>
            {module.moduleName}
            {module.deploymentType === "layerd" && (
              <PencilSquareIcon
                className="w-4 h-4 ml-2 text-blue-500 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingIndex(index);
                  setNewModuleName(module.moduleName);
                }}
              />
            )}
            {module.deploymentType === "layerd" && (
              <TrashIcon
              className={`w-4 h-4 ml-2 ${disabledModules.includes(module.moduleId) ? 'text-gray-400' : 'text-red-500'} cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabledModules.includes(module.moduleId)) {
                  setConfirmDeleteState({
                    isOpen: true,
                    moduleId: module.moduleId
                  });
                }
              }}
              />
            )}
          </>
        )}
      </TD>
      <TD>
      {(() => {
          switch (module.deploymentType) {
            case 'base':
              return <Badge color={BadgeColor.Indigo}>base</Badge>;
            case 'layerd':
              return <Badge color={BadgeColor.Green}>layerd</Badge>;
            case 'sems':
              return <Badge color={BadgeColor.Yellow}>sems</Badge>;
            default:
              return <Badge color={BadgeColor.Indigo}>base</Badge>;
          }
        })()}
      </TD>
      <TD>
      {(() => {
          switch (module.moduleType) {
            case 'iotedge':
              return <Badge color={BadgeColor.Indigo}>iotedge</Badge>;
            case 'api':
              return <Badge color={BadgeColor.Purple}>api</Badge>;
            case 'compose':
              return <Badge color={BadgeColor.Yellow}>compose</Badge>;
            default:
              return <Badge color={BadgeColor.Indigo}>iotedge</Badge>;
          }
        })()}
      </TD>
      <TD>{module.version}</TD>
    </TR>
  ));

  return (
    <>
      <Table>
        <THead>
          <TH>API</TH>
          <TH>Module Status</TH>
          <TH>Config Sync</TH>
          <TH>Config Status</TH>
          <TH>Module Name</TH>
          <TH>Stack</TH>
          <TH>Type</TH>
          <TH>Version</TH>
        </THead>
        <TBody>{moduleRows}</TBody>
      </Table>
      <Modal modalState={modalState} setModalState={setModalState}></Modal>
      {confirmDeleteState.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>Are you sure you want to delete the module?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setConfirmDeleteState({ isOpen: false, moduleId: "" })}
                className="mr-2 p-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(confirmDeleteState.moduleId);
                  setConfirmDeleteState({ isOpen: false, moduleId: ""});
                }}
                className="p-2 bg-red-500 text-white rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ModuleModal({ module }: { module: ModuleData }) {
  return (
    <Tab.Group>
      <div className="h-full flex flex-col">
        <Tab.List>
          <div className="grid grid-cols-3 p-2 bg-vibrant-blue text-white rounded-sm">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`rounded-sm ${selected ? 'bg-blue-500 text-white' : ''}`}>Module Configuration</button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`rounded-sm ${selected ? 'bg-blue-500 text-white' : ''}`}>Methods</button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`rounded-sm ${selected ? 'bg-blue-500 text-white' : ''}`}>Logs</button>
              )}
            </Tab>
          </div>
        </Tab.List>
        <Tab.Panels as="div" className="flex-grow grow overflow-y-auto">
          <Tab.Panel className="h-full flex flex-col">
            <ModuleConfig moduleName={module.moduleId} appMessage={module.appMessage} moduleStatus={module.status} />
          </Tab.Panel>
          <Tab.Panel className="h-full flex flex-col">
            <DirectMethods moduleName={module.moduleId} />
          </Tab.Panel>
          <Tab.Panel className="h-full flex flex-col">
            <ModuleLogs moduleName={module.moduleId} />
          </Tab.Panel>
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}
