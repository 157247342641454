import { useState } from "react";
import Button from "../../../components/Input/Button";
import { useParams } from "react-router-dom";
import JsonEditor from "../../../components/Input/JsonEditor";
import { easApiHooks } from "../../../api/edgeAdministrationShell/easApiHooks";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { toast } from 'react-toastify';

interface ModuleConfigProps {
  moduleName: string
  appMessage?: string
  moduleStatus: string
}

export default function ModuleConfig({ moduleName, appMessage, moduleStatus }: ModuleConfigProps) {
  const { deviceId } = useParams();

  const { data: config, isLoading, isIdle, isError, error, refetch } = easApiHooks.useGetTwinConfig<string>(deviceId, moduleName, {
    select: (data) => JSON.stringify(data, null, 4),
    staleTime: Infinity,
    onError: (error) => {
      console.log(`ERROR: Could not retrieve current opcua module configuration`);
    }
  });

  if (isLoading || isIdle)
    return <>Loading</>

  if (isError) {
    return (
      <>
        <div className="flex my-2 gap-2">
          <div className="grow bg-red-100 border border-red-400 p-2">Could not retrieve twin config. {error.code} {JSON.stringify(error.response?.data)}</div>
          <Button onClick={() => refetch()} processing={isLoading}>Retry</Button>
        </div>
        <JsonForm moduleName={moduleName} appMessage={appMessage} moduleStatus={moduleStatus} data={""} />
      </>
    )
  }

  return (
    <JsonForm moduleName={moduleName} appMessage={appMessage} moduleStatus={moduleStatus} data={config} />
  );
}


interface JsonFormProps {
  moduleName: string
  appMessage?: string
  moduleStatus: string
  data: string
}
function JsonForm({ moduleName, appMessage, moduleStatus, data }: JsonFormProps) {
  const [twinConfigForm, setTwinConfig] = useState(data);
  const { deviceId } = useParams();

  const queryClient = useQueryClient();
  const { mutate, isLoading: mutIsLoading } = useMutation<any, AxiosError>({
    mutationFn: () => {
      const payload = JSON.parse(twinConfigForm);
      return easApi.updateTwinConfig(deviceId, moduleName, payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getTwinConfig', deviceId, moduleName] })
      queryClient.invalidateQueries({ queryKey: ['getConfigStatus', deviceId] })
      toast.success(`Success: Scheduled config update for ${moduleName}.`)
    },
    onError: (err) => {
      var errData: any = err.response?.data
      console.log(errData)
      toast.error(`Error: ${JSON.stringify(errData.message)}`)
    }
  });

  const handleFormChange = (newValue: string) => {
    setTwinConfig(newValue);
  }


  return (
    <>
      <div className="grow">
        <JsonEditor value={twinConfigForm} onChange={handleFormChange} />
      </div>
      {appMessage && appMessage !== "configuration successfull" && <div className="max-h-36 overflow-y-auto bg-red-100 border border-red-400 mt-2 p-2">
        {appMessage}
      </div>}
      {(moduleStatus==="delete_scheduled" || moduleStatus==="deploy_scheduled")? 
      <div className="max-h-36 overflow-y-auto bg-yellow-100 border border-yellow-400 mt-2 p-2">Cannot set Twin config in current Module-State</div>: 
      (<Button processing={mutIsLoading} className="mt-2" onClick={() => mutate()}>Set Twin Config</Button>)}
    </>
  )
}