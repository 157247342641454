import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-java";

interface JsonEditorProps {
  value: string,
  readOnly?: boolean,
  onChange?: (newValue: string) => void
}

export default function JsonEditor({value, onChange, readOnly}: JsonEditorProps) {
  return (
    <AceEditor
      mode="java"
      name="jsoneditor"
      onChange={onChange}
      fontSize={16}
      height="100%"
      width="100%"
      readOnly={readOnly}
      highlightActiveLine={true}
      value={value}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
      }} />
  )
}