import { UseMutationOptions, useMutation, useQuery } from "react-query";
import { spApi } from "./starterPackagesApi";
import { AxiosError } from "axios";

import { queryClient } from "../../config/queryConfig"
import { ContractCreate, ContractGet, ContractUpdate } from "./interfaces/contracts";
import { OrderGet } from "./interfaces/orders";
import { EdgeDeviceGet, EdgeDeviceUpdate } from "./interfaces/edge_devices";
import { MachineCreate, MachineGet, MachineUpdate } from "./interfaces/machines";
import { SpApiError } from "./interfaces/spApiError";
import { ValidationError } from "webpack";

const useGetOrders = (filter?: string) => useQuery<OrderGet[], AxiosError<SpApiError>>({
  queryKey: ['sp', 'getOrders', filter],
  queryFn: () => spApi.getOrders(filter),
});

const useGetOrder = (orderId: string | undefined, posId: string | undefined) => useQuery<OrderGet, AxiosError>({
  queryKey: ['sp', 'getOrder', orderId, posId],
  queryFn: () => spApi.getOrder(orderId, posId),
  enabled: orderId != undefined && posId != undefined
})

const useAddMachineToOrder = (orderId: string | undefined, posId: string | undefined) => useMutation<OrderGet, AxiosError, string>({
  mutationFn: (machineNumber) => spApi.postAddMachineToOrder(orderId, posId, machineNumber),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getOrder', orderId, posId] }),
});

const useRemovedMachinefromOrder = (orderId: string | undefined, posId: string | undefined) => useMutation<ContractGet, AxiosError, string>({
  mutationFn: (machineNumber) => spApi.deleteRemoveMachineFromOrder(orderId, posId, machineNumber),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getOrder', orderId, posId] }),
});

const useGetEdgeDevice = (edge_device_id?: string) => useQuery<EdgeDeviceGet, AxiosError<SpApiError>>({
  queryKey: ['sp', 'getEdgeDevice', edge_device_id],
  queryFn: () => spApi.getEdgeDevice(edge_device_id),
  enabled: !!edge_device_id
});

const useGetEdgeDevices = (filter?: string) => useQuery<EdgeDeviceGet[], AxiosError>({
  queryKey: ['sp', 'getEdgeDevices', filter],
  queryFn: () => spApi.getEdgeDevices(filter)
});

const useUpdateEdgeDevice = (edge_device_id: string | undefined) => useMutation<EdgeDeviceGet, AxiosError, EdgeDeviceUpdate>({
  mutationFn: (payload) => spApi.updateEdgeDevice(edge_device_id, payload),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeDevice', edge_device_id] })
});

const useGetContracts = (filter?: string) => useQuery<ContractGet[], AxiosError<SpApiError>>({
  queryKey: ['sp', 'getContracts', filter],
  queryFn: () => spApi.getContracts(filter)
});

const useGetContract = (orderId: string | undefined, posId: string | undefined) => useQuery<ContractGet, AxiosError>({
  queryKey: ['sp', 'getContract', orderId, posId],
  queryFn: () => spApi.getContract(orderId, posId)
})

const useUpdateContract = (orderId: string | undefined, posId: string | undefined, mutationOptions?: UseMutationOptions<ContractGet, AxiosError, ContractUpdate>) => useMutation<ContractGet, AxiosError, ContractUpdate>(
  (payload) => spApi.updateContract(orderId, posId, payload), {
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getContract', orderId, posId] }),
  ...mutationOptions
});

const useAddMachineToContract = (orderId: string | undefined, posId: string | undefined) => useMutation<ContractGet, AxiosError, string>({
  mutationFn: (machineNumber) => spApi.postAddMachineToContract(orderId, posId, machineNumber),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getContract', orderId, posId] }),
});

const useRemoveMachineFromContract = (orderId: string | undefined, posId: string | undefined) => useMutation<ContractGet, AxiosError, string>({
  mutationFn: (machineNumber) => spApi.deleteRemoveMachineFromContract(orderId, posId, machineNumber),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getContract', orderId, posId] }),
});

const useCreateContract = () => useMutation<ContractGet, AxiosError<SpApiError | ValidationError>, ContractCreate>({
  mutationFn: (payload: ContractCreate) => spApi.createContract(payload),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getContracts'] })
});

const useGetEdgeMachines = (filter?: string) => useQuery<MachineGet[], AxiosError<SpApiError>>({
  queryKey: ['sp', 'getEdgeMachines', filter],
  queryFn: () => spApi.getEdgeMachines(filter)
})

const useGetEdgeMachine = (machine_number?: string) => useQuery<MachineGet, AxiosError>({
  queryKey: ['sp', 'getEdgeMachine', machine_number],
  queryFn: () => spApi.getEdgeMachine(machine_number)
})

const useUpdateEdgeMachine = (machine_number: string | undefined, mutationOptions?: UseMutationOptions<MachineGet, AxiosError, MachineUpdate>) => useMutation<MachineGet, AxiosError, MachineUpdate>(
  (payload) => spApi.updateEdgeMachine(machine_number, payload), {
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachine', machine_number] }),
  ...mutationOptions
})

const useDeleteEdgeMachine = () => useMutation<any, AxiosError, any>({
  mutationFn: (machine_number) => spApi.deleteEdgeMachine(machine_number),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachines'] })
})

const useCreateEdgeMachine = (mutationOptions?: UseMutationOptions<MachineGet, AxiosError, MachineCreate>) => useMutation<MachineGet, AxiosError, MachineCreate>(
  (payload) => spApi.createEdgeMachine(payload), {
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ['sp', 'getEdgeMachines'] }),
  ...mutationOptions
});

const useGetCountryCodes = () => useQuery<string[], AxiosError>({
  queryKey: ['sp', 'getCountryCodes'],
  queryFn: () => spApi.getCountryCodes()
})

const useGetCurrencyCodes = () => useQuery<string[], AxiosError>({
  queryKey: ['sp', 'getCurrencyCodes'],
  queryFn: () => spApi.getCurrencyCodes()
})

export const spApiQueries = {
  useGetOrders,
  useGetOrder,
  useAddMachineToOrder,
  useRemovedMachinefromOrder,

  useGetContracts,
  useGetContract,
  useCreateContract,
  useUpdateContract,
  useAddMachineToContract,
  useRemoveMachineFromContract,

  useGetEdgeDevice,
  useGetEdgeDevices,
  useUpdateEdgeDevice,

  useGetEdgeMachines,
  useGetEdgeMachine,
  useCreateEdgeMachine,
  useUpdateEdgeMachine,
  useDeleteEdgeMachine,

  useGetCountryCodes,
  useGetCurrencyCodes
}