import { SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";
import { ContractType, contractTypes } from '../../../api/starterPackages/interfaces/contracts';

interface ContractTypeSelectProps {
  contractType: ContractType,
  onContractTypeChange: (contractType: ContractType) => void
}

export function ContractTypeSelect({ contractType, onContractTypeChange }: ContractTypeSelectProps) {

  const onHandleChange = (newValue: SingleValue<any>) => {
    onContractTypeChange(newValue ? newValue.value : "")
  }

  const value = {
    value: contractType,
    label: contractType,
  }

  const contractTypesOptions = Object.entries(contractTypes).map(([key, value]) => {
    return {
      label: value,
      value: value
    }
  });

  return (
    <SearchableSelect
      options={contractTypesOptions}
      value={value}
      onChange={onHandleChange}
      isClearable={false}
    />
  )
}
