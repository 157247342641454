import { useParams } from "react-router-dom";
import { Heading, HeadingButton, HeadingColor } from "../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { spApiQueries } from "../../api/starterPackages/starterPackagesQueries";
import DictionaryList, { DictionaryListEntries } from "../../components/Table/DictionaryList";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ContractEdit from "./ContractEdit";
import { IndeterminateCheckbox } from "../../components/Input/FormElements";
import { formatError } from "../../utils/formatError";

var tableData: DictionaryListEntries = {
  "Order": "",
  "Contract Id": "",
  "Start Date": "",
  "End Date": "",
  "Estimated Edge Online Date": "",
  "Initial contract amount": "",
  "Initial contract currency": "",
  "Contract Type": "",
  "Contreact Gea Service": "",
  "Contract Gea Sales": "",
  "Customer": "",
  "Contract Admin Insight Partner": "",
  "Are network settings available": "",
  "Bonus recipient": "",
  "Comment": "",
  "Contract Id Internal": "",
  "Last updated by": "",
  "Last updated on": ""
}

export default function ContractDetail() {
  const { orderId, posId } = useParams();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data: contract, isFetching, isLoading, isIdle, isSuccess, error } = spApiQueries.useGetContract(orderId, posId);
  const queryClient = useQueryClient();
  const updateEdgeDeviceMutation = spApiQueries.useUpdateContract(orderId, posId, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sp', 'getContract', orderId, posId] })
      setIsEditing(false);
    },
    onError(error) {
      toast.error(`ERROR: Could not update contract. ${formatError(error)}`)
    },
  });

  if (contract) {
    tableData = {
      "Order": `${contract.order_id} - ${contract.pos_id}`,
      "Contract Id": contract.contract_id,
      "Start Date": contract.start_date,
      "End Date": contract.end_date,
      "Estimated Edge Online Date": contract.estimated_edge_online_date,
      "Initial contract amount": contract.initial_contract_amount,
      "Initial contract currency": contract.initial_contract_amount_currency,
      "Contract Type": contract.contract_type,
      "Contact Gea Service": contract.contract_gea_service,
      "Contact Gea Sales": contract.contract_gea_sales,
      "Contact Customer (Email)": contract.contract_customer,
      "Contact Admin Insight Partner": contract.contract_customer_admin_insight_partner,
      "Are network settings available": <IndeterminateCheckbox checked={contract.is_network_setting_available} disabled />,
      "Bonus recipient": contract.bonus_recipient,
      "Comment": contract.comment
    }

    if (isEditing) {
      return (
        <ContractEdit contract={contract} mutationFunction={updateEdgeDeviceMutation} cancel={() => setIsEditing(false)} />
      )
    }
  }

  return (
    <div>
      <Heading processing={isFetching} color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        {isSuccess && <HeadingButton onClick={() => setIsEditing(!isEditing)}><PencilSquareIcon className="w-6 h-6 ml-5" />Edit</HeadingButton>}
      </Heading>
      <DictionaryList dictionary={tableData} processing={isLoading || isIdle} error={error?.message} />
    </div>
  )
}