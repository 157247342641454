import { msalInstance } from "../index";
import { easApiLoginRequest } from "../config/authConfig";
import { EventStreamContentType, fetchEventSource } from '../utils/fetchSSE';
import { Dispatch, SetStateAction } from "react";

class FatalError extends Error { }

const getBearerToken = async () => {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(easApiLoginRequest);
    return `Bearer ${tokenResponse.accessToken}`;
  } catch (error) {
    msalInstance.acquireTokenRedirect(easApiLoginRequest);
  }
  return "";
}

interface GridEvent {
  id: string,
  topic: string,
  subject: string,
  eventType: string,
  data: {
    properties: {},
    systemProperties: {
      "iothub-content-type": "application/json",
      "iothub-content-encoding": "utf-8",
      "iothub-connection-device-id": string,
      "iothub-connection-module-id": string,
      "iothub-connection-auth-method": "{\"scope\":\"module\",\"type\":\"sas\",\"issuer\":\"iothub\",\"acceptingIpFilterRule\":null}", "iothub-connection-auth-generation-id": "638367678913814573", "iothub-enqueuedtime": "2024-02-28T13:57:35.4680000Z", "iothub-message-source": "Telemetry"
    },
    body: {
      header: {
        messageSchemaVersion: number,
        messageId: string,
        correlationId: string,
        messageType: string,
        messageTs: string
      },
      payload: {
        edgeDeviceId: string,
        connectionId: string,
        varName: string,
        value: string
      }
    }
  },
  dataVersion: string,
  metadataVersion: string,
  eventTime: string
}

export const subscribeTopic = async (topic: string, ctrl: AbortController, setNodeValue: Dispatch<SetStateAction<any>>) => {
  await fetchEventSource(`${process.env.REACT_APP_API_GES_URI}/subscribe/${topic}`, {
    headers: {
      'Authorization': await getBearerToken(),
    },
    signal: ctrl.signal,
    async onopen(response) {
      if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        // client-side errors are usually non-retriable:
        //throw new FatalError();
      } else {
        //throw new RetriableError();
      }
    },
    onmessage(msg) {
      try {
        const data = JSON.parse(msg.data) as GridEvent;
        const varName = data.data.body.payload.varName;
        const varValue = data.data.body.payload.value;

        setNodeValue((previous: any) => {
          return {
            ...previous,
            [varName]: varValue
          }
        });
      } catch (err) {
        // Ignore malformed event data
      }
      // if the server emits an error message, throw an exception
      // so it gets handled by the onerror callback below:
      if (msg.event === 'FatalError') {
        //throw new FatalError(msg.data);
      }
    },
    onclose() {
      console.log('OnClose');
      // if the server closes the connection unexpectedly, retry:
      //throw new RetriableError();
    },
    onerror(err) {
      console.log('OnError');
      if (err instanceof FatalError) {
        //throw err; // rethrow to stop the operation
      } else {
        // do nothing to automatically retry. You can also
        // return a specific retry interval here.
      }
    }
  });
}