import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { spApiQueries } from '../../../api/starterPackages/starterPackagesQueries';
import { TBody, TD, TH, THead, TR, Table } from '../../../components/Table/TableComponents';
import Badge from '../../../components/Typography/Badge';
import { HeadingButton } from '../../../components/Typography/Heading';
import { BadgeColor } from '../../../components/Typography/Badge';
import { MachineGet } from '../../../api/starterPackages/interfaces/machines';


export default function MachineList() {
  const [filter, setFilter] = useState('');
  const { deviceId } = useParams();
  const { isLoading, isError, isIdle, data: edgeDevice, error } = spApiQueries.useGetEdgeDevice(deviceId);

  if (isLoading || isIdle)
    return <div>Loading</div>

  if (isError)
    return <div>Error: {error.message}</div>
  
  return (
    <div className="@container w-full">
      <div className="hidden @2xl:block">
        <MachineTable machines={edgeDevice.machines} filter={filter} setFilter={setFilter} edgeDeviceId={deviceId}/>
      </div>
      <div className="block @2xl:hidden">
        <MachineCards machines={edgeDevice.machines} filter={filter} setFilter={setFilter} edgeDeviceId={deviceId}/>
      </div>
    </div>
  );
}


// Used for both types of displaying
interface MachineListProps {
  machines: MachineGet[],
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
  edgeDeviceId: any
}
function MachineTable({ machines, filter, setFilter, edgeDeviceId }: MachineListProps) {
  const navigate = useNavigate();
  const { machineNumber } = useParams();

  const deviceItems = machines.map((machine) =>
    <TR onClick={() => navigate(`/machines/${machine.machine_number}`)} className={`${machineNumber === machine.machine_number ? "bg-gray" : "bg-white hover:bg-gray-100"}`} key={machine.machine_number}>
      <TD>{machine.machine_number}</TD>
      <TD>{machine.is_opcua_configured ? <Badge color={BadgeColor.Green}>done</Badge>: <Badge color={BadgeColor.Purple}>miss</Badge>}</TD>
      <TD><Badge>{machine.machine_name}</Badge></TD>
    </TR>
  );

  return (
    <div className="rounded-sm">
      <Table>
        <THead>
          <TR>
            <TH>Machine Number</TH>
            <TH>OPC-UA Config</TH>
            <TH>
              <div className="flex">
                <div className="grow">Machine Name</div>
                <HeadingButton onClick={() => navigate(`/machines/create?edgeDeviceId=${edgeDeviceId}`)} className="mr-5"><PlusCircleIcon className="w-6 h-6" />Add Machine</HeadingButton>
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{deviceItems}</TBody>
      </Table>
    </div>
  )
}

function MachineCards({ machines, filter, setFilter, edgeDeviceId }: MachineListProps) {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const deviceItems = machines.map((machine) =>
    <div
      onClick={() => navigate(`/machines/${machine.machine_number}`)}
      key={machine.machine_number}
      className={`p-2 rounded-sm border border-1 ${deviceId === machine.machine_number ? "bg-gray-100" : "bg-white"}`}
    >
      <div className="space-x-1">
        <Badge>{machine.machine_number}</Badge>
        <Badge>{machine.machine_name}</Badge>
      </div>
      <div className="font-medium truncate">{machine.machine_name}</div>
      <div className="truncate">{machine.machine_number}</div>
    </div>
  );

  return (
    <div className="space-y-2">
      <div className="sticky top-0 rounded-sm bg-vibrant-blue p-[9px] font-medium">
        Machines
      </div>
      {deviceItems}
    </div>
  )
}