import { useParams } from "react-router-dom";
import { Heading, HeadingButton, HeadingColor } from "../../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { spApiQueries } from "../../../api/starterPackages/starterPackagesQueries";
import DictionaryList, {DictionaryListEntries, } from "../../../components/Table/DictionaryList";
import { Dispatch, SetStateAction, useState } from "react";
import { Input, Select, Option } from "../../../components/Input/FormElements";
import clm from "country-locale-map";
import { toast } from "react-toastify";
import { OrderGet } from "../../../api/starterPackages/interfaces/orders";
import {
  EdgeDeviceGet,
  EdgeDeviceUpdate,
} from "../../../api/starterPackages/interfaces/edge_devices";
import { formatError } from "../../../utils/formatError";
import { ObjectSelect } from "../../../components/Input/Select/ObjectSelect";
import {
  DeviceData,
  easApiHooks,
  SmartEmsData,
  SmartEMSDescription,
} from "../../../api/edgeAdministrationShell/easApiHooks";
import Button, { ButtonSize } from "../../../components/Input/Button";

var tableData: DictionaryListEntries = {
  Order: "",
  "Country-Code": "",
  City: "",
  "Geo-Location": "",
  Customer: "",
  "Smart-EMS Description": "",
  "Proposed Smart-EMS Description (Not synced with SMART EMS)": "",
};

export default function BasicInformation() {
  const { deviceId } = useParams();
  const [isEditing, setIsEditing] = useState<boolean>(false); // Same component as mutation, same component as save, cancel and edit button
  const spEdgeDevice = spApiQueries.useGetEdgeDevice(deviceId);
  const easDevice = easApiHooks.useGetDevice(deviceId);
  const semsDevice = easApiHooks.useGetSmartEmsInfo(deviceId);

  if (spEdgeDevice.data && easDevice.data && semsDevice.data) {
    const geoLocation = formatGeoLocation(spEdgeDevice.data);
    const country = formatCountry(spEdgeDevice.data.country_code);
    const smartEmsDescription = formatSmartEmsDescription(
      spEdgeDevice.data.country_code,
      spEdgeDevice.data.order?.customer_name,
      spEdgeDevice.data.city,
      spEdgeDevice.data.edge_device_id
    );
    const order =
      spEdgeDevice.data.order_id && spEdgeDevice.data.pos_id
        ? `${spEdgeDevice.data.order_id} - ${spEdgeDevice.data.pos_id}`
        : "";

    tableData = {
      Order: order,
      "Country-Code": country,
      City: spEdgeDevice.data.city,
      "Geo-Location": geoLocation,
      Customer: spEdgeDevice.data.order?.customer_name,
      Description: easDevice.data?.description,
      "Smart-EMS Edge-Gateway Description": semsDevice.data?.description,
      "Proposed Smart-EMS Description (Not synced)": smartEmsDescription,
    };

    if (isEditing) {
      return (
        <BasicInformationForm
          edgeDevicePayload={spEdgeDevice.data}
          smartEmsDevice={semsDevice.data}
          easDevice={easDevice.data}
          setIsEditing={setIsEditing}
          cancel={() => setIsEditing(false)}
        />
      );
    }
  }

  const errorMessage = spEdgeDevice.isError
    ? `${spEdgeDevice.error.message} ${spEdgeDevice.error.response?.data.detail}`
    : undefined;

  return (
    <div>
      <Heading processing={spEdgeDevice.isFetching} color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        {spEdgeDevice.isSuccess && (
          <HeadingButton onClick={() => setIsEditing(!isEditing)}>
            <PencilSquareIcon className="w-6 h-6 ml-5" />
            Edit
          </HeadingButton>
        )}
      </Heading>
      <DictionaryList
        dictionary={tableData}
        processing={!spEdgeDevice.data || !easDevice.data || !semsDevice.data}
        error={errorMessage}
      />
    </div>
  );
}

interface BasicInformationForm {
  edgeDevicePayload: EdgeDeviceGet;
  smartEmsDevice: SmartEmsData;
  easDevice: DeviceData;

  setIsEditing: Dispatch<SetStateAction<boolean>>;
  cancel: () => void;
}

function BasicInformationForm({
  edgeDevicePayload,
  smartEmsDevice,
  easDevice,
  setIsEditing,
  cancel,
}: BasicInformationForm) {
  const [edgeDeviceFormData, setEdgeDeviceFormData] = useState<EdgeDeviceUpdate>(edgeDevicePayload);
  const { data: countryCodes } = spApiQueries.useGetCountryCodes();
  const { data: orders } = spApiQueries.useGetOrders();
  const { data: selectedOrder } = spApiQueries.useGetOrder(
    edgeDeviceFormData.order_id?.toString(),
    edgeDeviceFormData.pos_id?.toString()
  );
  const [newDescription, setNewDescription] = useState<SmartEMSDescription>({
    description: easDevice.description || "",
  });
  const { deviceId } = useParams();
  const updateSmartEmsDescription = easApiHooks.usePostSmartEmsDescription(deviceId);
  const updateEdgeDeviceMutation = spApiQueries.useUpdateEdgeDevice(deviceId);

  const onSubmit = () => {
    updateEdgeDeviceMutation.mutate(edgeDeviceFormData, {
      onSuccess: () => {
        setIsEditing(false);
      },
      onError(error) {
        toast.error(
          `ERROR: Could not update edge device information ${deviceId}. ${formatError(
            error
          )}`
        );
      },
    });
    updateSmartEmsDescription.mutate(newDescription, {
      onError(error) {
        toast.error(
          `ERROR: Could not sync edge device description ${deviceId}. ${JSON.stringify(error.response?.data)}`
        );
      },
    } 
      
    );
  };

  const processing = !countryCodes || !orders;

  if (orders && countryCodes) {
    const currentOrder = orders.filter(
      (order) =>
        order.order_id === edgeDeviceFormData.order_id &&
        order.pos_id === edgeDeviceFormData.pos_id
    )[0];

    const handleChange = (ev: any) => {
      const { name, value } = ev.target;
      setEdgeDeviceFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    };

    const handleNewDescription = (ev: any) => {
      const { value } = ev.target;
      setNewDescription(() => ({
        description: value,
      }));
    };

    const handleOrderChange = (order: OrderGet) => {
      setEdgeDeviceFormData({
        ...edgeDeviceFormData,
        order_id: order.order_id,
        pos_id: order.pos_id,
      });
    };

    const proposedSmartEmsDescription = formatSmartEmsDescription(
      edgeDeviceFormData.country_code,
      currentOrder?.customer_name,
      edgeDeviceFormData.city,
      edgeDevicePayload.edge_device_id
    );

    const applyDescription = (description: string) => {
      setNewDescription({ description: description });
    };

    tableData = {
      Order: (
        <ObjectSelect<OrderGet>
          value={selectedOrder}
          options={orders}
          id={"order_id"}
          columns={["pos_id"]}
          onSelectChange={handleOrderChange}
        />
      ),
      "Country-Code": (
        <Select
          name="country_code"
          value={edgeDeviceFormData.country_code || ""}
          onChange={handleChange}
          className="p-1 pr-10"
        >
          {countryCodes.map((countryCode, index) => (
            <Option key={index} value={countryCode}>
              {formatCountry(countryCode)}
            </Option>
          ))}
        </Select>
      ),
      "City": (
        <Input
          type="text"
          name="city"
          value={edgeDeviceFormData.city || ""}
          onChange={handleChange}
          className="p-1"
        />
      ),
      "Geo-Location": (
        <div className="space-x-3">
          <Input
            type="text"
            name="latitude"
            value={edgeDeviceFormData.latitude || ""}
            onChange={handleChange}
            className="p-1"
          />{" "}
          °N
          <Input
            type="text"
            name="longitude"
            value={edgeDeviceFormData.longitude || ""}
            onChange={handleChange}
            className="p-1"
          />{" "}
          °W
        </div>
      ),
      "Customer": currentOrder?.customer_name,
      "Description (Click on save to sync this description to all Systems)": (
        <Input
          type="text"
          name="description"
          value={newDescription.description || ""}
          onChange={handleNewDescription}
          className="p-1 inline-block w-full"
        />
      ),
      "Smart-EMS Edge-Gateway Description": smartEmsDevice.description && (
        <div className="space-x-4">
          <span>{smartEmsDevice.description}</span>{" "}
          <Button
            size={ButtonSize.Small}
            onClick={() => applyDescription(smartEmsDevice.description)}
          >
            Apply Description
          </Button>
        </div>
      ),
      "Proposed Smart-EMS Description": (proposedSmartEmsDescription && (
        <div className="space-x-4">
          <span>{proposedSmartEmsDescription}</span>
          <Button
            size={ButtonSize.Small}
            onClick={() => applyDescription(proposedSmartEmsDescription)}
          >
            Apply Description
          </Button>
        </div>
      )) || (
        <span className="italic">
          Assign order, country & city to generate Proposed Smart EMS
          Description
        </span>
      ),
    };
  }

  return (
    <div>
      <Heading
        color={HeadingColor.Gray}
        processing={updateEdgeDeviceMutation.isLoading}
      >
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={onSubmit}>
          <PencilSquareIcon className="w-6 h-6 ml-5" />
          Save
        </HeadingButton>
        <HeadingButton onClick={() => cancel()}>
          <XMarkIcon className="w-6 h-6 ml-5" />
          Cancel
        </HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} processing={processing} />
    </div>
  );
}

function formatCountry(countryCode?: string | null) {
  if (!countryCode) return "";
  const countryName = clm.getCountryNameByAlpha3(countryCode);
  if (countryName) return `${countryCode} - ${countryName}`;
  return countryCode;
}

function formatGeoLocation(edge_device: EdgeDeviceGet) {
  var geoLocation = "";
  if (edge_device.latitude && edge_device.longitude)
    geoLocation = `${edge_device.latitude || "-"}°N ${
      edge_device.longitude || "-"
    }°W`;
  return geoLocation;
}

function formatSmartEmsDescription(
  countryCode?: string | null,
  customer?: string | null,
  city?: string | null,
  deviceId?: string | null
) {
  if (countryCode && city && customer && deviceId)
    return `${countryCode}_${customer}_${city}_#${deviceId}`;
  return "";
}
