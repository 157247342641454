import { useState } from "react";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";
import React from "react";
import Button from "../../../components/Input/Button";
import { useParams } from "react-router-dom";
import { Input } from "../../../components/Input/FormElements";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import JsonEditor from "../../../components/Input/JsonEditor";

interface LogData {
  payload: [{
    id: string
    payload: string
  }]
}

export default function ModuleLogs({ moduleName }: { moduleName: string }) {
  const [lineNumber, setLineNumber] = useState(25);
  const { deviceId } = useParams();

  const { data, isLoading, isIdle, isError, error, refetch } = useQuery<LogData, AxiosError>({
    queryKey: ['getModuleLogs', deviceId, moduleName],
    queryFn: () => {
      const payload = {
        "methodName": "GetModuleLogs",
        "methodPayload":
        {
          "schemaVersion": "1.0",
          "items": [
            {
              "id": moduleName,
              "filter": {
                "tail": lineNumber
              }
            }
          ],
          "encoding": "none",
          "contentType": "text"
        }
      }
      return easApi.invokeDirectMethod(deviceId, "$edgeAgent", payload)
    },
  });

  const handleFormChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setLineNumber(Number(value));
  }

  if (isLoading || isIdle)
    return <div>Loading...</div>

  if (isError)
    return <div>Error: {error.message}</div>

  return (
    <>
      <div className="grow">
        <JsonEditor value={data.payload[0]?.payload} readOnly={true}/>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Input
          type="number"
          name="lineNumber"
          value={lineNumber}
          onChange={handleFormChange} />
        <Button onClick={() => refetch()} processing={isLoading}>Get Module Logs</Button>
      </div>
    </>
  )
}