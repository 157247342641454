import { useState } from 'react';
import { toast } from 'react-toastify';

interface RenameDialogProps {
    sourcePath: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (inputValue: string) => void;
  }
  
  
  export default function RenameDialog ({ sourcePath, isOpen, onClose, onConfirm }: RenameDialogProps){
    const [inputValue, setInputValue] = useState('');

    if (!isOpen) return null;

    // extract path-prefix, filename and file-type
    let pathPrefix = ""
    let fileName = ""
    const regex = /((?:\/\/)?(?:.*\/)?)([^\/]+)$/;
    const match = sourcePath.match(regex);
    if (match) {
      pathPrefix = match[1]
      fileName = match[2]
    }
    else{
      toast.error(`could not parse file-name from given path: ${sourcePath}`)
    }
    console.log(pathPrefix, fileName)

    const generateTargetPath = (newFileName: string) => {
      return `${pathPrefix}${(pathPrefix)? "/": ""}${newFileName}`
    }
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
          <h2 className="text-lg text-center font-semibold">Rename File</h2>
          <label htmlFor="sourceFile" className="block text-sm font-medium text-gray-700 mt-5">
            Current File Name
          </label>
          <input
            id="sourceFile"
            type="text"
            value={fileName}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
            readOnly={true}
          />
          <label htmlFor="targetFile" className="block text-sm font-medium text-gray-700 mt-5">
            New File Name
          </label>
          <input
            id="targetFile"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="mt-1 p-2 border border-gray-300 rounded w-full"
            placeholder="New File Name"
          />
          <div className="mt-6 flex justify-center space-x-4">
            <button
              onClick={() => onConfirm(generateTargetPath(inputValue))}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              OK
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };