import axios, { AxiosError } from "axios";
import { msalInstance } from "../..";
import { easApiLoginRequest } from "../../config/authConfig";
import { queries } from "@testing-library/react";

export const easApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`,
});

easApiInstance.interceptors.request.use(async function (config: any) {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(easApiLoginRequest);
    config.headers["Authorization"] = `Bearer ${tokenResponse.accessToken}`;
  } catch (error) {
    msalInstance.acquireTokenRedirect(easApiLoginRequest);
  }
  return config;
}, undefined);


export interface DirectMethodResponse<T> {
  status: number,
  payload: T
}

export interface DirectMethodRequest {
  methodName: string,
  methodPayload: any
}

/*
* This function unwraps the direct method responses, into AxiosErrors
* you can use it when handling direct methods
*/
function handleDirectMethodResponse(response: DirectMethodResponse<any>) {
  if (response.status >= 400) {
    throw new AxiosError(response.payload, response.status.toString());
  } else {
    return response;
  }
}

const getDevices = async () => {
  const { data } = await easApiInstance.get('/v1/devices');
  return data;
}

const getDevice = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/device?device=${deviceId}`);
  return data;
}

const getSmartEmsInfo = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/smartems/info`);
  return data;
}

const getDeviceTags = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/tags`);
  return data;
}


const postDeviceTags = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/tags`, payload);
  return data;
}

const getSmartEmsStatus = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/smartems/status`);
  return data;
}

const getModules = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/modules`);
  return data;
}

const getConfigStatus = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/config/status`, payload);
  return data;
}

const getSmartEmsConfigLan = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/smartems/config/lan`);
  return data;
}

const getSmartEmsConfigExport = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/smartems/config/export`);
  return data;
}

const postSmartEmsConfigLan = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/smartems/configure/lan`, payload);
  return data;
}

const postSmartEMSDescription = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/smartems/description`, payload);
  return data;
}

const scanNetwork = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/gea-app-net-discover/methods`, payload);
  return handleDirectMethodResponse(data);
}

const nmShow = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/gea-app-cmd-proxy/methods`, payload);
  return handleDirectMethodResponse(data);
}

const getTwinConfig = async (deviceId: string | undefined, moduleName: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/twin/config/${moduleName}`)
  return data;
}

const updateTwinConfig = async (deviceId: string | undefined, moduleName: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/twin/config/${moduleName}`, payload)
  return data;
}

const invokeDirectMethod = async (deviceId: string | undefined, module: string, payload: DirectMethodRequest) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/${module}/methods`, payload);
  return handleDirectMethodResponse(data);
}

const getDeploymentStatus = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/deployment/status`);
  return data;
}

const getConnectionStatus = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/connection/status`);
  return data;
}

const getRemoteControlCredentials = async () => {
  const { data } = await easApiInstance.get(`/v1/remoteControl/credentials`);
  return data;
}

const getCmdLanIpConfig = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/cmd/lan/ip/config`);
  return data;
}

const postDeviceCmdSmartemsCheck = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/cmd/smartems/check`);
  return data;
}

const getDeviceCmdStatus = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/cmd/status`);
  return data;
}

const getDeviceCmdConfigShow = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/cmd/config/show`);
  return data;
}

const getDeviceCmdNmConfig = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/cmd/nm/config`);
  return data;
}

const postDeviceCmdLanConfig = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/cmd/lan/config`, payload);
  return data;
}

const getDeviceCellularConfig = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.get(`/v1/${deviceId}/smartems/config/cellular`);
  return data;
}

const postDeviceCellularConfig = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/smartems/config/cellular`, payload);
  return data;
}

const postDeviceNetworkDiscover = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/network/discover`, payload);
  return data;
}

const postWebFtpCreateSession = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/createSession`, payload);
  return data;
}

const postWebFtpDeleteSession = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/deleteSession`, payload);
  return data;
}

const postWebFtpInfoSession = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/infoSession`, payload);
  return data;
}


const postWebFtpList = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/list`, payload);
  return data;
}

const postWebFtpReadFile = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/readFile`, payload, { responseType: 'blob' });
  return data;
}

const postWebFtpWriteFile = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/writeFile`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
  return data;
}

const postWebFtpMakeDir = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/makeDir`, payload);
  return data;
}

const postWebFtpRemoveDir = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/removeDir`, payload);
  return data;
}

const postWebFtpRemoveFile = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/removeFile`, payload);
  return data;
}

const postWebFtpRenameFile = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/webftp/renameFile`, payload);
  return data;
}

const postInfluxBucketCreate = async (deviceId: string | undefined) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/influx/bucket/create`);
  return data;
}

const postDeploymentAddModule = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/deployment/addModule`, payload);
  return data;
}

const postDeploymentDeleteModule = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/deployment/removeModule`, null, {params: {module_id: payload}});
  return data;
}

const postDeploymentModuleNames = async (deviceId: string | undefined, payload: any) => {
  const { data } = await easApiInstance.post(`/v1/${deviceId}/deployment/moduleNames`, payload);
  return data;
}


export const easApi = {
  getDevices,
  getDevice,
  getSmartEmsInfo,
  postDeviceTags,
  getDeviceTags,
  getSmartEmsStatus,
  getModules,
  getConfigStatus,
  getSmartEmsConfigLan,
  postSmartEmsConfigLan,
  postSmartEMSDescription,
  scanNetwork,
  getTwinConfig,
  updateTwinConfig,
  invokeDirectMethod,
  getDeploymentStatus,
  getConnectionStatus,
  getRemoteControlCredentials,
  nmShow,
  getCmdLanIpConfig,
  postDeviceCmdSmartemsCheck,
  getDeviceCmdStatus,
  postDeviceCmdLanConfig,
  getDeviceCellularConfig,
  postDeviceCellularConfig,
  postDeviceNetworkDiscover,
  postWebFtpCreateSession,
  postWebFtpList,
  postWebFtpReadFile,
  postWebFtpDeleteSession,
  postWebFtpInfoSession,
  postWebFtpMakeDir,
  postWebFtpRemoveDir,
  postWebFtpRemoveFile,
  postWebFtpRenameFile,
  getDeviceCmdNmConfig,
  getSmartEmsConfigExport,
  getDeviceCmdConfigShow,
  postInfluxBucketCreate,
  postWebFtpWriteFile,
  postDeploymentAddModule,
  postDeploymentDeleteModule,
  postDeploymentModuleNames
}