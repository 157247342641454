import { NavLink, Route, Routes, useParams } from "react-router-dom";
import DeviceConfig from "./DeviceConfig/DeviceConfig";
import ModuleList from "./ModuleConfig/ModuleList";
import RemoteControl from "./RemoteControl/RemoteControl";
import NetworkScan from "./NetworkScan/NetworkScan";
import { useQueryClient } from 'react-query';
import SmartEmsInfo from "./DeviceInfo/SmartEmsInfo";
import ConnectionStatus from "./DeviceInfo/ConnectionInfo";
import BasicInformation from "./DeviceInfo/BasicInformation";
import { easApiHooks } from "../../api/edgeAdministrationShell/easApiHooks";
import OPCUABrrowserPage from "../../pages/OPCUABrowser";
import GrafanaFrames from "./DeviceInfo/GrafanaFrames";
import MachineList from "./Machines/MachineList";
import DeploymentInfo from "./ModuleConfig/DeploymentInfo";
import { FTPBrowser } from "../../pages/FTPBrowser";

export default function DeviceDetail() {
  const { deviceId } = useParams();
  const tabs = [
    {
      title: "Info",
      href: "",
      element: <div className="space-y-4"><ConnectionStatus /><BasicInformation /><SmartEmsInfo /><GrafanaFrames /></div>
    },
    {
      title: "Device Config",
      href: "device-config",
      element: <DeviceConfig />
    },
    {
      title: "Module Config",
      href: "module-config",
      element: <div className="space-y-4"><DeploymentInfo /><ModuleList /></div>
    },
    {
      title: "Remote Control",
      href: "remote-control",
      element: <RemoteControl />
    },
    {
      title: "Network Scan",
      href: "network-scan",
      element: <NetworkScan />
    },
    {
      title: "Machines",
      href: "machines",
      element: <MachineList />
    }
  ]

  return (
    <div className="space-y-2 h-full overflow-hidden overflow-y-auto flex flex-col">
      <div className="sticky top-0 flex space-x-1 rounded-sm bg-vibrant-blue p-2 z-20">
        {tabs.map((tab) => (
          <NavLink to={tab.href} end
            key={tab.href}
            className={({ isActive }: { isActive: boolean }) => `w-full rounded-sm p-2.5 font-medium ring-night-blue text-center ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-1
              ${isActive ? "bg-blue-50 text-vibrant-blue" : "text-blue-100 hover:bg-white/[0.25] hover:text-white"}`}
          >
            {tab.title}
          </NavLink>
        ))}
      </div>
      <div className=" relative z-10 bg-white rounded-sm p-2 space-y-2 relative grow">
        <DeviceCard />
        <Routes>
          {tabs.map((tab) => (
            <Route path={tab.href} key={tab.href} element={tab.element} />
          ))}
          <Route path="opcua" element={<OPCUABrrowserPage />} />
          <Route path="ftp" element={<FTPBrowser deviceId={deviceId} />}></Route>
        </Routes>
      </div>
    </div>
  )
}

function DeviceCard() {
  const { deviceId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, isError, isIdle, data, error } = easApiHooks.useGetDevice(deviceId, {
    onSuccess: () => queryClient.invalidateQueries('devices')
  });

  if (isLoading || isIdle)
    return (
      <div className="p-2 rounded-sm ring-1 ring-inset animate-pulse ring-gray-200">
        <div className="h-[40px] bg-slate-300 rounded-sm" />
      </div>
    )

  if (isError)
    return <div>Error: {error.message} {JSON.stringify(error.response?.data, null, 4)}</div>

  return (
    <div className={`text-center text-4xl truncate p-2 rounded-sm ring-1 ring-inset ${data.deviceStatus === 'Connected' ? 'bg-green-100 ring-green-600/20' : 'bg-red-100 ring-red-600/10'}`}>
      {data.deviceName}
    </div>
  )
}
