import { useParams } from "react-router-dom";
import Badge, { BadgeColor } from "../../../components/Typography/Badge";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { rcApi, rcApiInstance } from "../../../api/rcApi";
import { AxiosError } from "axios";
import Button, { ButtonColor } from "../../../components/Input/Button";
import { toast } from "react-toastify";
import { easApi } from "../../../api/edgeAdministrationShell/easApi";

interface StatusData {
  active: boolean
  id: string
  message: string
  service: string
}

export default function RemoteControl() {

  const { isLoading, isError, isIdle, data } = useQuery<any, AxiosError>({
    queryKey: ['getRemoteControlCredentials'],
    queryFn: () => easApi.getRemoteControlCredentials(),
    onSuccess: (data) => (
      rcApiInstance.interceptors.request.use(async function (config: any) {
        config.baseURL = `https://${data.apiEndpoint}`
        config.headers["API-Key"] = data.apiKeyValue;
        return config;
      }, undefined)
    ),
    staleTime: Infinity,  // We don't want to refetch the credentials
  });

  if (isLoading || isIdle) {
    return <div>Loading...</div>
  }

  if (isError)
    return <div>No access to Remote Control API</div>


  return (
    <div className="space-y-2">
      <div className="grid grid-cols-3 font-bold">
        <div>Service</div>
        <div>Status</div>
        <div>Actions</div>
      </div>
      <RemoteControlService service="portainer" credentials={data} />
      <RemoteControlService service="webssh" credentials={data} />
    </div>
  );
}

function RemoteControlService({ service, credentials }: { service: string, credentials: any }) {
  const { deviceId } = useParams();

  const queryClient = useQueryClient();
  const { isLoading, isError, isIdle, isSuccess, data } = useQuery<StatusData, AxiosError>({
    queryKey: ['getServiceStatus', service, deviceId],
    queryFn: () => rcApi.checkEndpointStatus(service, deviceId)
  });

  const { mutate: connect, isLoading: isConnecting } = useMutation<any, AxiosError>({
    mutationFn: () => {
      return rcApi.addRCEndpoint(service, deviceId);
    },
    onSuccess: () => {
      toast.success(`Success: Opened Endpoint to the ${service} service.`);
    },
    onError: (error) => {
      toast.error(`ERROR: Could not connect endpoint ${JSON.stringify(error.message, null, 4)}`);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['getServiceStatus', service, deviceId]); // invalidate query after 3 seconds, because updating the serviceStatus takes some time.
      }, 3000);
    }
  })


  let status;
  if (isLoading || isIdle)
    status = <Badge color={BadgeColor.Gray}>Loading</Badge>;

  if (isError)
    status = <Badge color={BadgeColor.Red}>Unknown due to error</Badge>

  if (isSuccess)
    status = data.active ? <Badge color={BadgeColor.Green}>open</Badge> : <Badge color={BadgeColor.Red}>closed</Badge>

  return (
    <div className="grid grid-cols-3">
      <div className="align-middle">{service}</div>
      <div className="align-middle">{status}</div>
      <div className="space-x-2">
        <Button processing={isConnecting} onClick={() => connect()}>Connect</Button>
        <Button color={ButtonColor.Green} onClick={() => openInNewTab(`https://${credentials.rcBasicAuthUser}:${credentials.rcBasicAuthPw}@${credentials.fwdEndpoint}/${deviceId}/${service}`)}>Open</Button>
        <Button color={ButtonColor.Red} onClick={() => toast.info("Closing the tunnel is not yet implemented")}>Close</Button>
      </div>
    </div>
  )
}

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};