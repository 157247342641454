import { Dispatch, SetStateAction, useState } from "react";
import { Input, Option, Select } from "../../components/Input/FormElements";
import Button from "../../components/Input/Button";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { AnonymousCredentials, ConnectionParams, UserNameCredentials } from "./OPCUABrowser";
import { easApi } from "../../api/edgeAdministrationShell/easApi";

interface EndpointResult {
  status: number,
  payload: OPCUAEndpoints[]
}

interface OPCUAEndpoints {
  endpointUrl: string,
  securityMode: string,
  securityPolicyUri: string,
  userIdentityTokens: {
    tokenType: string,
    securityPolicyUri?: string
  }[]
}

interface ConnectionParamsFormParams {
  endpoint: string,
  setConnectionParams: Dispatch<SetStateAction<ConnectionParams | undefined>>
}

/**
 * Form for selecting messageSecurityMode, securityPolicy, credentialType, etc... based on the available endpoints
 */
export default function ConnectionParamsForm({ endpoint, setConnectionParams }: ConnectionParamsFormParams) {
  const { deviceId } = useParams();
  const [selectedEndpointIndex, setSelectedEndpointIndex] = useState(0);
  const [selectedUserIdentityTokenIndex, setSelectedUserIdentityindex] = useState(0);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // Gets available endpoints of the OPCUA server
  const { data, isLoading, isIdle, isError } = useQuery<EndpointResult, AxiosError>({
    queryKey: ['getOpcuaEndpoints', deviceId, endpoint],
    queryFn: async () => {
      const payload = {
        "methodName": "getEndpoints",
        "methodPayload":
        {
          "schemaVersion": "1.0",
          "endpoint": endpoint,
          "credentials": { type: "Anonymous" },
          "messageSecurityMode": "None",
          "securityPolicy": "None",
        }
      }
      return easApi.invokeDirectMethod(deviceId, "gea-app-opcua-browser", payload);
    },
    staleTime: Infinity
  });

  const handleEndpointSelect = (ev: any) => {
    setSelectedEndpointIndex(ev.target.value);
    setSelectedUserIdentityindex(0);
  }

  const handleUserIdentityTokenSelect = (ev: any) => {
    setSelectedUserIdentityindex(ev.target.value);
  }

  if (isLoading || isIdle) {
    return <div>Retrieving OPCUA Server endpoints</div>
  }

  if (isError) {
    return <div>Failed retrieving OPCUA Server endpoints</div>
  }

  const endpoints = data.payload;

  const handleBrowse = () => {
    const credentials: UserNameCredentials | AnonymousCredentials = endpoints[selectedEndpointIndex].userIdentityTokens[selectedUserIdentityTokenIndex].tokenType === "UserName" ? {
      type: "UserName",
      userName: userName,
      password: password
    } : {
      type: "Anonymous"
    }
    const connectionParams = {
      endpoint: endpoint,
      credentials: credentials,
      messageSecurityMode: endpoints[selectedEndpointIndex].securityMode,
      securityPolicy: endpoints[selectedUserIdentityTokenIndex].securityPolicyUri
    }
    setConnectionParams(connectionParams)
  }

  return (
    <div className="flex space-x-2">
      <Select value={selectedEndpointIndex} onChange={handleEndpointSelect}>
        {endpoints.map((endpoint, index) =>
          <Option key={index} value={index}>{endpoint.securityMode} {getSecurityPolicy(endpoint.securityPolicyUri)}</Option>
        )}
      </Select>

      <Select value={selectedUserIdentityTokenIndex} onChange={handleUserIdentityTokenSelect}>
        {endpoints[selectedEndpointIndex].userIdentityTokens.map((userIdentityToken, index) =>
          <Option key={index} value={index}>{userIdentityToken.tokenType} {getSecurityPolicy(userIdentityToken.securityPolicyUri)}</Option>
        )}
      </Select>

      {endpoints[selectedEndpointIndex].userIdentityTokens[selectedUserIdentityTokenIndex].tokenType === "UserName" &&
        <>
          <Input value={userName} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setUserName(ev.target.value)} placeholder="User Name" />
          <Input value={password} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)} placeholder="Password" />
        </>
      }

      <Button onClick={handleBrowse}>
        Browse
      </Button>
    </div>
  )
}

/**
 * Get short form of securityPolicy Uri
 * @param securityPolicyUri 
 * @returns 
 */
function getSecurityPolicy(securityPolicyUri?: string) {
  if (!securityPolicyUri)
    return ""

  const indexOfHash = securityPolicyUri?.indexOf('#');
  if (indexOfHash !== -1) {
    const securityPolicy = securityPolicyUri.substring(indexOfHash + 1);
    return securityPolicy;
  }
  return securityPolicyUri;
}
