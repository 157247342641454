import { useParams } from "react-router-dom";
import { useState } from "react";
import Badge, { BadgeColor } from "../../../components/Typography/Badge";
import { Heading, HeadingButton } from "../../../components/Typography/Heading";
import { InformationCircleIcon, PencilSquareIcon, XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { easApi } from '../../../api/edgeAdministrationShell/easApi';
import { AxiosError } from "axios";
import DictionaryList, { DictionaryListEntries } from "../../../components/Table/DictionaryList";
import Button from "../../../components/Input/Button";
import { toast } from "react-toastify";
import { InputLabel } from "../../../components/Input/InputLabel";

interface DeploymentInfoData {
  deviceId: string
  deploymentId: string
  priority: number
  targeted: boolean
  applied: boolean
  success: boolean
}

export default function DeploymentInfo() {
  const { deviceId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingModule, setIsAddingModule] = useState(false);
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<string>("");
  const [moduleType, setModuleType] = useState<string>("gea-app-opcua-client");
  const [moduleAlias, setModuleAlias] = useState<string>("");

  const queryClient = useQueryClient()

  const { data: deploymentInfo, isLoading, isError, isIdle, error, isFetching } = useQuery<DeploymentInfoData, AxiosError>({
    queryKey: ['getDeploymentStatus', deviceId],
    queryFn: () => easApi.getDeploymentStatus(deviceId),
    refetchInterval: 30000   // Polling
  });

  const mutation = useMutation(
    (newDeploymentId: string) => easApi.postDeviceTags(deviceId, { deviceTags: { deployment: newDeploymentId } }),
    {
      onSuccess: () => {
        toast.success(`Successfully set new base deployment`)
        setIsEditing(false);
        queryClient.invalidateQueries(['getDeploymentStatus', deviceId]);
      }
    }
  );


  const { mutate: addModuleMutation, isLoading: isAdding} = useMutation(
    () => easApi.postDeploymentAddModule(deviceId, { moduleType, moduleAlias }),
    {
      onSuccess: () => {
        toast.success(`Scheduled module ${moduleAlias} for deployment`)
        setIsAddingModule(false);
        queryClient.invalidateQueries(['getModules', deviceId]);
      }
    }
  );

  var tableData: DictionaryListEntries = {
    "Deployment-ID": "",
    "Applied": "",
    "Success": ""
  }

  if (deploymentInfo) {
    tableData = {
      "Base Deployment": isEditing ? (
        <select 
          value={selectedDeploymentId} 
          onChange={(e) => setSelectedDeploymentId(e.target.value)}
        >
          <option value="base">Insight Partner</option>
          <option value="vpn-only">Remote Partner</option>
        </select>
      ) : (
        <Badge color={BadgeColor.Blue}>{deploymentInfo.deploymentId}</Badge>
      ),
      "Applied": <Badge color={deploymentInfo.applied ? BadgeColor.Green : BadgeColor.Red}>{deploymentInfo.applied ? "True" : "False"}</Badge>,
      "Success": <Badge color={deploymentInfo.success ? BadgeColor.Green : BadgeColor.Red}>{deploymentInfo.success ? "True" : "False"}</Badge>
    }
  }

  const errorMessage = isError ? `${error.message}` : undefined

  return (
    <div className="space-y-4">
      <div>
        <Heading processing={isFetching}>
          <InformationCircleIcon className="w-7 h-7 mr-1" />
          Module Deployment
          {isEditing ? (
            <>
              <HeadingButton><PencilSquareIcon 
                className="w-7 h-7 ml-2 cursor-pointer" 
                onClick={() => mutation.mutate(selectedDeploymentId)}
              />Save</HeadingButton>
              <HeadingButton><XMarkIcon 
                className="w-7 h-7 ml-2 cursor-pointer" 
                onClick={() => setIsEditing(false)}
              />Cancel</HeadingButton>
            </>
          ) : (
            <>
              <HeadingButton><PencilSquareIcon 
                className="w-7 h-7 ml-2 cursor-pointer" 
                onClick={() => {
                  setSelectedDeploymentId(deploymentInfo?.deploymentId || "");
                  setIsEditing(true);
                }}
              />Edit</HeadingButton>
              <HeadingButton><PlusIcon 
                className="w-7 h-7 ml-2 cursor-pointer" 
                onClick={() => setIsAddingModule(true)}
              />Add Module</HeadingButton>
            </>
          )}
        </Heading>
        <DictionaryList dictionary={tableData} processing={isLoading || isIdle} error={errorMessage}/>
      </div>
      
      {isAddingModule && (
        <div className="flex flex-col gap-2 p-4 border rounded shadow-md bg-blue-50">
        <div className="grid gap-4 grid-cols-2">
          <div className="flex flex-col">
            <label className="text-sm mb-1 font-bold" htmlFor="moduleType">Module Type:</label>
            <select id="moduleType" value={moduleType} onChange={(e) => setModuleType(e.target.value)} className="p-2 border rounded">
              <option value="gea-app-opcua-client">gea-app-opcua-client</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-sm mb-1 font-bold" htmlFor="moduleAlias">Module Name:</label>
            <input id="moduleAlias" type="text" value={moduleAlias} onChange={(e) => setModuleAlias(e.target.value)} className="p-2 border rounded"/>
          </div>
          <div className="col-span-2 flex gap-4">
            <Button processing={isAdding} onClick={() => addModuleMutation()}>ADD</Button>
            <Button processing={isAdding} onClick={() => setIsAddingModule(false)}>Cancel</Button>
          </div>
        </div>
      </div>
      )}
    </div>
  )
}
