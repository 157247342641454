import React from "react";

interface TableModel {
  col1: string | React.ReactNode
  col2: string | React.ReactNode
}

interface SimpleTableProps {
  tableData: TableModel[]
}

export default function SimpleTable({tableData}: SimpleTableProps) {

  const tableRows = tableData.map((row, index) =>
    <div className="grid grid-cols-2 p-2 even:bg-slate-200 rounded-sm" key={index}>
      <div className="font-medium">{row.col1}</div>
      <div>{row.col2}</div>
    </div>
  )

  return <div className="divide-y divide-solid bg-white">{tableRows}</div>
}